import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { take } from 'rxjs';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { SellerListingService } from 'src/app/core/services';
import { ISellerListing } from 'src/app/models';

@Component({
  selector: 'app-cash-offer-estimates',
  templateUrl: './cash-offer-estimates.component.html',
  styleUrl: './cash-offer-estimates.component.scss',
})
export class CashOfferEstimatesComponent {
  @Input() listing: ISellerListing;
  @Input() isExpanded: boolean = false;
  @Output() onComplete = new EventEmitter<string>();
  lowestOffer: number = 0;
  highestOffer: number = 0;
  appName: string = '';
  constructor(
    private readonly listingService: SellerListingService,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {
    this.appName = this.config.appName;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isExpanded } = changes;

    if (isExpanded?.currentValue) {
      this.getCashOfferValues();
    }
  }

  getCashOfferValues() {
    if (!this.listing?._id) return;

    this.listingService
      .getLeadRetailPrice(this.listing._id)
      .pipe(take(1))
      .subscribe({
        next: (response: any) => {
          const { inclusiveReports } = response;
          if (inclusiveReports?.length > 0) {
            inclusiveReports.forEach((report) => {
              if (report.name === 'Tradein') {
                this.lowestOffer = report.prices.find((p) => p.name === 'Rough Base')?.price ?? 0;
                this.highestOffer = report.prices.find((p) => p.name === 'Clean Base')?.price ?? 0;
              }
            });
          }
        },
        error: () => {},
      });
  }
}
