<div class="section-width">
  <div ngbAccordion #accordion="ngbAccordion" class="app-accordion expanded">
    @for (section of sections; track section.id) {
      <div [ngbAccordionItem]="section.id">
        <div ngbAccordionHeader>
          <button class="header-container" ngbAccordionButton>
            <div class="header-title">{{ section.title }}</div>
            @if (section.isComplete) {
              <img src="assets/verified.svg" alt="completed" width="32" />
            } @else {
              <div class="btn-gray btn-toggle">
                <div class="icon icon-left" [ngClass]="{ isExpanded: accordion.isExpanded(section.id) }"></div>
              </div>
            }
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody completeSection="body">
            <ng-container [ngTemplateOutlet]="getSectionTemplate(section.id)"></ng-container>
          </div>
        </div>
      </div>
    }
  </div>

  <div class="d-flex pt-4">
    <button class="btn btn-primary me-3" (click)="saveChanges()">Save changes</button>
    <button class="btn btn-secondary" (click)="resubmit()">
      {{ listing?.acquisitionSource ? 'Resubmit' : 'Submit' }}
    </button>
  </div>
</div>

<!-- Section Templates -->
<ng-template #vehicleDetailsTemplate>
  <div class="accordian-max-width">
    <app-cash-offer-vehicle-details
      [listing]="listing"
      (onComplete)="onSectionComplete($event)"
    ></app-cash-offer-vehicle-details>
  </div>
</ng-template>

<ng-template #exteriorInteriorTemplate>
  <div class="accordian-max-width">
    <app-cash-offer-exterior-details
      [listing]="listing"
      (onComplete)="onSectionComplete($event)"
    ></app-cash-offer-exterior-details>
  </div>
</ng-template>

<ng-template #conditionHistoryTemplate>
  <div class="accordian-max-width">
    <app-cash-offer-vehicle-history
      [listing]="listing"
      (onComplete)="onSectionComplete($event)"
    ></app-cash-offer-vehicle-history>
  </div>
</ng-template>

<ng-template #offerEstimatesTemplate>
  <div class="accordian-max-width">
    <app-cash-offer-estimates
      [listing]="listing"
      [isExpanded]="accordion.isExpanded('offer-estimates')"
      (onComplete)="onSectionComplete($event)"
    ></app-cash-offer-estimates>
  </div>
</ng-template>
