<div class="p-3">
  <div class="offer-estimate-section p-3">
    <div class="title">Your vehicles estimate cash offer:</div>
    <div class="estimate-amount">
      {{ lowestOffer | currency: 'USD' : 'symbol' : '1.0-0' }} -
      {{ highestOffer | currency: 'USD' : 'symbol' : '1.0-0' }}
    </div>
    <div class="disclaimer">*Not an exact offer. Based on Blackbook value estimate.</div>
  </div>

  <div class="next-steps-section p-3 my-3">
    <div class="next-steps-section-title">What happens next?</div>
    <div class="d-flex py-3">
      <img src="assets/conversation-chat-icon.svg" alt="conversation chat" />
      <div class="ms-2">
        <div class="content-title">Connect with dealers</div>
        <div class="content-description">
          Dealers will reach out on DealNow chat or send you an offer to buy your car.
        </div>
      </div>
    </div>
    <div class="d-flex py-3">
      <img src="assets/green-medal-icon.svg" alt="green medal" width="28" />
      <div class="ms-2">
        <div class="content-title">Accept an offer</div>
        <div class="content-description">Like an offer? Accept it and close the deal all within the app.</div>
      </div>
    </div>
    <div class="d-flex py-3">
      <img src="assets/green-money-coin.svg" alt="green money coin" />
      <div class="ms-2">
        <div class="content-title">Get paid</div>
        <div class="content-description">
          The dealer will instantly send you funds to purchase your vehicle in your preferred payment method:
          electrically, mail a check or pick up,
        </div>
      </div>
    </div>
  </div>
  @if (appName === 'DealNow') {
    <div class="sell-privately">
      Use <span><a href="https://privateauto.com" target="_blank" class="privateauto-link">PrivateAuto</a></span>
    </div>
  }
</div>
