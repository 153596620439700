import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';
import { ISellerListing } from 'src/app/models';
import { AccordianCashOfferSection } from '../cash-offer-summary/cash-offer-summary.component';

@Component({
  selector: 'app-modal-cash-offer-summary',
  templateUrl: './modal-cash-offer-summary.component.html',
  styleUrl: './modal-cash-offer-summary.component.scss',
})
export class ModalCashOfferSummaryComponent implements OnInit {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter<boolean>();
  @Input() listing: ISellerListing;

  sections: AccordianCashOfferSection[] = [];
  submitted = false;
  modalConfig: ModalConfig = {
    name: 'Cash Offer Summary',
    modalDialogClass: 'modal-cash-offer-summary',

    beforeClose: async () => {
      this.isOpen = false;
      this.isOpenChange.emit(this.isOpen);
      this.onClose.emit(this.submitted);
      return true;
    },
  };
  isMobileView = false;

  onSectionsUpdated(sections: any[]): void {
    this.sections = sections;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobileView = event.target.innerWidth <= 768;
  }

  ngOnInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    const { isOpen } = simpleChanges;
    if (isOpen.currentValue) {
      this.modalComponent?.open();
      this.submitted = false;
    }
  }

  close(): void {
    this.modalComponent?.close();
  }

  onModalClose(submitted: boolean): void {
    this.submitted = submitted;
    this.modalComponent?.close();
  }
}
