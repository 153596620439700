import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { ModalConfig } from '../modal/modal.model';

@Component({
  selector: 'app-cash-vehicle-submitted-modal',
  templateUrl: './cash-vehicle-submitted-modal.component.html',
  styleUrls: ['./cash-vehicle-submitted-modal.component.scss'],
})
export class CashVehicleSubmittedModalComponent implements OnInit, OnChanges {
  @ViewChild('modal') private readonly modalComponent: ModalComponent;
  @Input() isOpen = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter<boolean>();
  @Output() onViewGarage = new EventEmitter<void>();

  modalConfig: ModalConfig = {};

  ngOnInit(): void {
    this.modalConfig = {
      name: 'Cash Vehicle Submitted Modal',
      hideLogo: true,
      beforeClose: async (isClosedByUser = true) => {
        this.isOpen = false;
        this.isOpenChange.emit(this.isOpen);
        this.onClose.emit(isClosedByUser);
        return true;
      },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isOpen } = changes;
    if (isOpen?.currentValue) {
      this.modalComponent?.open();
    }
  }

  onViewGarageClick(): void {
    this.onViewGarage.emit();
    this.modalComponent.close(false);
  }
}
