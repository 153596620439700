<div class="vehicle-history-container">
  <form [formGroup]="vehicleHistoryForm" class="history-form p-4">
    <div class="form-group">
      <label for="accidentHistory">Has the vehicle been in an accident?</label>
      <div class="form-flex-inline d-flex align-items-center me-2 mt-3">
        <div
          class="oval-select-btn"
          [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('accidentHistory')?.value === 2 }"
          (click)="onAccidentHistorySelect(2)"
        >
          2+
        </div>
        <div
          class="oval-select-btn ms-2"
          [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('accidentHistory')?.value === 1 }"
          (click)="onAccidentHistorySelect(1)"
        >
          1
        </div>
        <div
          class="oval-select-btn ms-2"
          [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('accidentHistory')?.value === 0 }"
          (click)="onAccidentHistorySelect(0)"
        >
          None
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="condition">What is the condition of the vehicle?</label>
      <div ngbDropdown class="btn-dropdown w-100">
        <button class="form-control d-flex align-items-center justify-content-between text-start" ngbDropdownToggle>
          <span class="ellipsis">
            {{ vehicleHistoryForm.get('condition')?.value || 'Select condition' }}
          </span>
          <img alt="arrow down" src="assets/chevron-down.svg" class="dropdown-icon" />
        </button>
        <div ngbDropdownMenu class="dropdown-container w-100">
          @for (condition of vehicleConditions; track condition) {
            <button
              ngbDropdownItem
              class="dropdown px-3"
              (click)="vehicleHistoryForm.patchValue({ condition: condition })"
            >
              {{ condition }}
            </button>
          }
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="tireCondition">How are the tires?</label>
      <div ngbDropdown class="btn-dropdown w-100">
        <button class="form-control d-flex align-items-center justify-content-between text-start" ngbDropdownToggle>
          <span class="ellipsis">
            {{ vehicleHistoryForm.get('tireCondition')?.value || 'Select tire condition' }}
          </span>
          <img alt="arrow down" src="assets/chevron-down.svg" class="dropdown-icon" />
        </button>
        <div ngbDropdownMenu class="dropdown-container w-100">
          @for (condition of tireConditions; track condition) {
            <button
              ngbDropdownItem
              class="dropdown px-3"
              (click)="vehicleHistoryForm.patchValue({ tireCondition: condition })"
            >
              {{ condition }}
            </button>
          }
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="hasWheelDamage">Any wheel damage?</label>
      <div class="form-flex-inline d-flex align-items-center me-2 mt-3">
        <div
          class="oval-select-btn ms-2"
          [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('hasWheelDamage')?.value === true }"
          (click)="onWheelDamageSelect(true)"
        >
          Yes
        </div>
        <div
          class="oval-select-btn ms-2"
          [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('hasWheelDamage')?.value === false }"
          (click)="onWheelDamageSelect(false)"
        >
          No
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="hasWheelDamage">Does your vehicle have any mechanical defects or dashboard warnings? </label>
      <div class="form-flex-inline d-flex align-items-center me-2 mt-3">
        <div
          class="oval-select-btn ms-2"
          [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('hasMechanicalDefects')?.value === true }"
          (click)="onMechanicalDefectsSelect(true)"
        >
          Yes
        </div>
        <div
          class="oval-select-btn ms-2"
          [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('hasMechanicalDefects')?.value === false }"
          (click)="onMechanicalDefectsSelect(false)"
        >
          No
        </div>
      </div>
    </div>
    @if (vehicleHistoryForm.get('hasMechanicalDefects')?.value === true) {
      <div class="form-group">
        <label for="mechanicalDefectTypes">Select all that apply:</label>
        <div class="mechanical-defects-container mt-3">
          @for (defect of mechanicalDefectTypes; track defect) {
            <div class="form-check mb-2">
              <input
                class="form-check-input"
                type="checkbox"
                [id]="'defect-' + defect.value"
                [checked]="isDefectSelected(defect.value)"
                (change)="onMechanicalDefectToggle(defect.value)"
              />
              <div class="form-check-label">
                {{ defect.label }}
              </div>
            </div>
          }

          <div class="form-check mb-2">
            <input
              class="form-check-input"
              type="checkbox"
              id="defect-other"
              [checked]="isOtherSelected"
              (change)="onOtherDefectToggle()"
            />
            <div class="form-check-label" for="defect-other">Other</div>
            @if (isOtherSelected) {
              <input
                type="text"
                class="form-control mt-2"
                placeholder="Please explain"
                [value]="getOtherDefectExplanation()"
                (change)="onOtherDefectExplanationChange($event)"
              />
            }
          </div>
        </div>
      </div>
    }

    <div class="form-group">
      <label for="hasDrivingIssues">Does your vehicle have any issues that would stop us from driving it?</label>
      <div class="form-flex-inline d-flex align-items-center me-2 mt-3">
        <div
          class="oval-select-btn ms-2"
          [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('hasDrivingIssues')?.value === true }"
          (click)="onDrivingIssuesSelect(true)"
        >
          Yes
        </div>
        <div
          class="oval-select-btn ms-2"
          [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('hasDrivingIssues')?.value === false }"
          (click)="onDrivingIssuesSelect(false)"
        >
          No
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="hasBeenSmoked">Has your vehicle ever been smoked in?</label>
      <div class="form-flex-inline d-flex align-items-center me-2 mt-3">
        <div
          class="oval-select-btn ms-2"
          [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('hasBeenSmoked')?.value === true }"
          (click)="onSmokedInSelect(true)"
        >
          Yes
        </div>
        <div
          class="oval-select-btn ms-2"
          [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('hasBeenSmoked')?.value === false }"
          (click)="onSmokedInSelect(false)"
        >
          No
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="numberOfKeys">How many keys are there for this vehicle?</label>
      <div class="form-flex-inline d-flex align-items-center me-2 mt-3">
        <div
          class="oval-select-btn ms-2"
          [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('numberOfKeys')?.value === 2 }"
          (click)="onKeyCountSelect(2)"
        >
          2+
        </div>
        <div
          class="oval-select-btn ms-2"
          [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('numberOfKeys')?.value === 1 }"
          (click)="onKeyCountSelect(1)"
        >
          1
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="ownershipType">What's the ownership status of your vehicle?</label>
      <div class="form-flex-inline d-flex align-items-center me-2 mt-3">
        <div
          class="oval-select-btn ms-2"
          [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('ownershipType')?.value === ownershipTypes.Owned }"
          (click)="onOwnershipTypeSelect(ownershipTypes.Owned)"
        >
          Owned
        </div>
        <div
          class="oval-select-btn ms-2"
          [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('ownershipType')?.value === ownershipTypes.Leased }"
          (click)="onOwnershipTypeSelect(ownershipTypes.Leased)"
        >
          Leased
        </div>
        <div
          class="oval-select-btn ms-2"
          [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('ownershipType')?.value === ownershipTypes.Financed }"
          (click)="onOwnershipTypeSelect(ownershipTypes.Financed)"
        >
          Financed
        </div>
      </div>
    </div>
    @if (vehicleHistoryForm.get('ownershipType')?.value === ownershipTypes.Financed) {
      <div class="form-group">
        <label for="isCreditCheckAuthorized"
          >Can the dealer pull a loan estimate on your vehicle? It won't affect your credit.</label
        >

        <div class="my-2 disclaimer">
          By clicking "Yes" you agree to the Spinwheel End User Agreement. Further, you are providing "written
          instructions" to Spinwheel Solutions, Inc. authorizing it to obtain your credit profile from any consumer
          reporting agency.
        </div>

        <div class="form-flex-inline d-flex align-items-center me-2 mt-3">
          <div
            class="oval-select-btn ms-2"
            [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('isCreditCheckAuthorized')?.value === true }"
            (click)="onCreditCheckAuthorizedSelect(true)"
          >
            Yes
          </div>
          <div
            class="oval-select-btn ms-2"
            [ngClass]="{ 'btn-primary': vehicleHistoryForm.get('isCreditCheckAuthorized')?.value === false }"
            (click)="onCreditCheckAuthorizedSelect(false)"
          >
            No
          </div>
        </div>
      </div>
    }

    <div class="form-group">
      <label for="titlePossession">Where is the title?</label>
      <div ngbDropdown class="btn-dropdown w-100">
        <button class="form-control d-flex align-items-center justify-content-between text-start" ngbDropdownToggle>
          <span class="ellipsis">
            {{ titleDisplayValue || 'Select one' }}
          </span>
          <img alt="arrow down" src="assets/chevron-down.svg" class="dropdown-icon" />
        </button>
        <div ngbDropdownMenu class="dropdown-container w-100">
          @for (titlePossession of titlePossessions; track titlePossession) {
            <button ngbDropdownItem class="dropdown px-3" (click)="onTitlePossessionSelect(titlePossession.value)">
              {{ titlePossession.label }}
            </button>
          }
        </div>
      </div>
    </div>

    <div class="form-actions">
      <button
        type="button"
        class="btn btn-primary next-btn"
        [disabled]="!vehicleHistoryForm.valid"
        (click)="completeSection('condition-history')"
      >
        Next
      </button>
    </div>
  </form>
</div>
