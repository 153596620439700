import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup, FormControl } from '@angular/forms';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SellerListingService } from 'src/app/core/services';
import { ISellerListing, OwnershipTypes, TitlePossessions } from 'src/app/models';

interface MechanicalDefect {
  value: string;
  label: string;
}

@Component({
  selector: 'app-cash-offer-vehicle-history',
  templateUrl: './cash-offer-vehicle-history.component.html',
  styleUrl: './cash-offer-vehicle-history.component.scss',
})
export class CashOfferVehicleHistoryComponent {
  @Input() listing: ISellerListing;
  @Output() onComplete = new EventEmitter<string>();

  vehicleHistoryForm: UntypedFormGroup;

  vehicleConditions = ['Excellent', 'Good', 'Fair'];
  yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  titleDisplayValue = '';
  isOtherSelected = false;

  tireConditions = ['New', 'Average wear', 'Need Replacement'];
  ownershipTypes = OwnershipTypes;

  mechanicalDefectTypes: MechanicalDefect[] = [
    { value: 'engine', label: 'Engine (e.g., Check Engine light on dashboard)' },
    { value: 'transmission', label: 'Transmission' },
    { value: 'airConditioning', label: 'Air-conditioning' },
    { value: 'electrical', label: 'Electrical (e.g., SRS/Airbag light on dashboard)' },
    { value: 'tirePressure', label: 'Tire Pressure (e.g., TPMS light on dashboard)' },
  ];

  titlePossessions = [
    { value: TitlePossessions.Lost, label: 'I lost the title' },
    { value: TitlePossessions.Held, label: 'I have the title' },
    { value: TitlePossessions.External, label: 'Loan/lease company has the title' },
  ];

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly listingService: SellerListingService,
    private readonly notificationService: NotificationService,
  ) {
    this.initializeForm();
  }

  private initializeForm() {
    this.vehicleHistoryForm = this.fb.group({
      accidentHistory: ['', Validators.required],
      condition: ['', Validators.required],
      tireCondition: ['', Validators.required],
      hasWheelDamage: ['', Validators.required],
      hasDrivingIssues: ['', Validators.required],
      hasBeenSmoked: ['', Validators.required],
      numberOfKeys: ['', Validators.required],
      hasMechanicalDefects: ['', Validators.required],
      ownershipType: ['', Validators.required],
      isCreditCheckAuthorized: [null],
      mechanicalDefects: new FormControl([]),
      titlePossession: ['', Validators.required],
    });

    this.vehicleHistoryForm.get('ownershipType').valueChanges.subscribe((ownershipType) => {
      const isCreditCheckAuthorizedControl = this.vehicleHistoryForm.get('isCreditCheckAuthorized');

      if (ownershipType === this.ownershipTypes.Financed) {
        isCreditCheckAuthorizedControl.setValidators(Validators.required);
      } else {
        isCreditCheckAuthorizedControl.clearValidators();
        isCreditCheckAuthorizedControl.setValue(null);
      }

      isCreditCheckAuthorizedControl.updateValueAndValidity();
    });
  }

  getFormValues() {
    return this.vehicleHistoryForm.value;
  }

  ngOnChanges(changes: SimpleChanges) {
    const { listing } = changes;
    if (listing?.currentValue) {
      this.populateFormFromListing();
    }
  }

  populateFormFromListing() {
    if (!this.listing) return;
    this.vehicleHistoryForm.patchValue({
      accidentHistory: this.listing.accidentHistory,
      condition: this.listing.condition,
      tireCondition: this.listing.tireCondition,
      hasWheelDamage: this.listing.hasWheelDamage,
      hasDrivingIssues: this.listing.hasDrivingIssues,
      hasBeenSmoked: this.listing.hasBeenSmoked,
      numberOfKeys: this.listing.numberOfKeys,
      ownershipType: this.listing.ownershipInfo.ownershipType,
      isCreditCheckAuthorized: this.listing.isCreditCheckAuthorized,
      hasMechanicalDefects: this.listing.hasMechanicalDefects,
      mechanicalDefects: this.listing.mechanicalDefects,
      titlePossession: this.listing.ownershipInfo.titlePossession,
    });

    const defaultDefectValues = this.mechanicalDefectTypes.map((d) => d.value);
    const mechanicalDefects = this.listing.mechanicalDefects || [];
    const hasOtherDefects = mechanicalDefects.some((defect) => !defaultDefectValues.includes(defect));

    if (hasOtherDefects) {
      this.isOtherSelected = true;
    }

    this.getTitlePossessionLabel();
  }

  completeSection(sectionId: string): void {
    if (!this.vehicleHistoryForm.valid) {
      return;
    }

    const formValues = this.vehicleHistoryForm.value;

    const updatedListing = {
      _id: this.listing._id,
      accidentHistory: formValues.accidentHistory,
      condition: formValues.condition,
      tireCondition: formValues.tireCondition,
      hasWheelDamage: formValues.hasWheelDamage,
      hasDrivingIssues: formValues.hasDrivingIssues,
      hasBeenSmoked: formValues.hasBeenSmoked,
      numberOfKeys: formValues.numberOfKeys,
      ownershipInfo: {
        ownershipType: formValues.ownershipType,
        titlePossession: formValues.titlePossession,
      },
      isCreditCheckAuthorized: formValues.isCreditCheckAuthorized,
      hasMechanicalDefects: formValues.hasMechanicalDefects,
      mechanicalDefects: formValues.mechanicalDefects,
    };

    this.listingService.createOrUpdateSellerListing(updatedListing).subscribe({
      next: (response) => {
        this.notificationService.notification('success', 'Vehicle history updated successfully');
        this.onComplete.emit(sectionId);
      },
      error: () => {
        this.notificationService.notification('error', 'Error updating vehicle history');
      },
    });
  }

  onAccidentHistorySelect(accidentHistory: number) {
    this.vehicleHistoryForm.patchValue({ accidentHistory });
  }

  onWheelDamageSelect(hasWheelDamage: boolean) {
    this.vehicleHistoryForm.patchValue({ hasWheelDamage });
  }

  onDrivingIssuesSelect(hasDrivingIssues: boolean) {
    this.vehicleHistoryForm.patchValue({ hasDrivingIssues });
  }

  onSmokedInSelect(hasBeenSmoked: boolean) {
    this.vehicleHistoryForm.patchValue({ hasBeenSmoked });
  }

  onKeyCountSelect(numberOfKeys: number) {
    this.vehicleHistoryForm.patchValue({ numberOfKeys });
  }

  onOwnershipTypeSelect(ownershipType: OwnershipTypes) {
    this.vehicleHistoryForm.patchValue({ ownershipType });
  }

  onMechanicalDefectsSelect(hasMechanicalDefects: boolean) {
    this.vehicleHistoryForm.patchValue({ hasMechanicalDefects });
  }

  onCreditCheckAuthorizedSelect(isCreditCheckAuthorized: boolean) {
    this.vehicleHistoryForm.patchValue({ isCreditCheckAuthorized });
  }

  onMechanicalDefectToggle(value: string): void {
    const currentDefects = [...(this.vehicleHistoryForm.get('mechanicalDefects').value || [])];
    const index = currentDefects.indexOf(value);

    if (index === -1) {
      currentDefects.push(value);
    } else {
      currentDefects.splice(index, 1);
    }

    this.vehicleHistoryForm.patchValue({ mechanicalDefects: currentDefects });
  }

  onOtherDefectToggle(): void {
    this.isOtherSelected = !this.isOtherSelected;
  }

  onOtherDefectExplanationChange(event: Event): void {
    const defect = (event.target as HTMLInputElement).value;
    const currentDefects = [...(this.vehicleHistoryForm.get('mechanicalDefects').value || [])];
    const defaultDefectValues = this.mechanicalDefectTypes.map((d) => d.value);
    const standardDefects = currentDefects.filter((d) => defaultDefectValues.includes(d));
    this.vehicleHistoryForm.patchValue({ mechanicalDefects: [...standardDefects, defect] });
  }

  isDefectSelected(value: string): boolean {
    const defects = this.vehicleHistoryForm.get('mechanicalDefects').value || [];
    return defects.includes(value);
  }

  getOtherDefectExplanation(): string {
    const defects = this.vehicleHistoryForm.get('mechanicalDefects').value || [];
    const defaultDefectValues = this.mechanicalDefectTypes.map((d) => d.value);
    const otherDefects = defects.find((d) => !defaultDefectValues.includes(d));
    return otherDefects || '';
  }

  onTitlePossessionSelect(titlePossession: string) {
    this.vehicleHistoryForm.patchValue({ titlePossession });
    this.getTitlePossessionLabel();
  }

  getTitlePossessionLabel() {
    const value = this.vehicleHistoryForm.get('titlePossession')?.value;
    this.titleDisplayValue = this.titlePossessions.find((t) => t.value === value)?.label || 'Select one';
  }
}
