<form [formGroup]="exteriorDetailsForm" class="exterior-details-form p-4">
  <div class="form-group mb-3">
    <label class="input-label" for="ExteriorColor">Exterior Color</label>
    <div ngbDropdown class="btn-dropdown w-100">
      <button
        class="form-control d-flex align-items-center justify-content-between text-start"
        ngbDropdownToggle
        [ngClass]="{
          'is-invalid':
            exteriorDetailsForm.get('ExteriorColor').invalid && exteriorDetailsForm.get('ExteriorColor').touched,
        }"
      >
        <span class="ellipsis">{{ exteriorDetailsForm.get('ExteriorColor').value || 'Select exterior color' }}</span>
        <img alt="arrow down" src="assets/chevron-down.svg" class="dropdown-icon" />
      </button>
      <div ngbDropdownMenu class="dropdown-container" aria-labelledby="exteriorColorDropdown">
        @for (color of exteriorColors; track color) {
          <button ngbDropdownItem class="dropdown px-3" (click)="onExteriorColorSelect(color)">
            {{ color }}
          </button>
        }
      </div>
    </div>
  </div>

  <div class="form-group mb-3">
    <label class="input-label" for="interiorColor">Interior Color</label>
    <div ngbDropdown class="btn-dropdown w-100">
      <button
        class="form-control d-flex align-items-center justify-content-between text-start"
        ngbDropdownToggle
        [ngClass]="{
          'is-invalid':
            exteriorDetailsForm.get('InteriorColor').invalid && exteriorDetailsForm.get('InteriorColor').touched,
        }"
      >
        <span class="ellipsis">{{ exteriorDetailsForm.get('InteriorColor').value || 'Select interior color' }}</span>
        <img alt="arrow down" src="assets/chevron-down.svg" class="dropdown-icon" />
      </button>
      <div ngbDropdownMenu class="dropdown-container" aria-labelledby="interiorColorDropdown">
        @for (color of interiorColors; track color) {
          <button ngbDropdownItem class="dropdown px-3" (click)="onInteriorColorSelect(color)">
            {{ color }}
          </button>
        }
      </div>
    </div>
  </div>

  <div class="form-group mb-3">
    <label class="input-label" for="exteriorDamage"
      >Does your vehicle have any exterior damage, including hail damage?</label
    >
    <div class="form-flex-inline d-flex align-items-center me-2 mt-3">
      <div
        class="oval-select-btn"
        [ngClass]="{ 'btn-primary': exteriorDetailsForm.get('hasExteriorDamage').value === true }"
        (click)="onExteriorDamageSelect(true)"
      >
        Yes
      </div>
      <div
        class="oval-select-btn ms-2"
        [ngClass]="{ 'btn-primary': exteriorDetailsForm.get('hasExteriorDamage').value === false }"
        (click)="onExteriorDamageSelect(false)"
      >
        No
      </div>
    </div>
  </div>

  <div class="form-group mb-3">
    <label class="input-label" for="windshieldDamage"
      >Does the windshield need replacing, have cracks, or have chips larger than a dime?</label
    >
    <div class="form-flex-inline d-flex align-items-center me-2 mt-3">
      <div
        class="oval-select-btn"
        [ngClass]="{ 'btn-primary': exteriorDetailsForm.get('needsWindshieldReplacement').value === true }"
        (click)="onWindshieldDamageSelect(true)"
      >
        Yes
      </div>
      <div
        class="oval-select-btn ms-2"
        [ngClass]="{ 'btn-primary': exteriorDetailsForm.get('needsWindshieldReplacement').value === false }"
        (click)="onWindshieldDamageSelect(false)"
      >
        No
      </div>
    </div>
  </div>

  <div class="form-group mb-3">
    <label class="input-label" for="hasAftermarketParts">Are there any aftermarket parts on the vehicle?</label>
    <div class="form-flex-inline d-flex align-items-center me-2 mt-3">
      <div
        class="oval-select-btn"
        [ngClass]="{ 'btn-primary': exteriorDetailsForm.get('hasAftermarketParts').value === true }"
        (click)="onAftermarketPartsSelect(true)"
      >
        Yes
      </div>
      <div
        class="oval-select-btn ms-2"
        [ngClass]="{ 'btn-primary': exteriorDetailsForm.get('hasAftermarketParts').value === false }"
        (click)="onAftermarketPartsSelect(false)"
      >
        No
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-start mt-4">
    <button
      class="btn btn-primary next-btn"
      (click)="completeSection('exterior-interior')"
      [disabled]="!exteriorDetailsForm.valid"
    >
      Next
    </button>
  </div>
</form>
