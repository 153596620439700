import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { VerificationType } from 'src/app/account/plaid-link/plaid-link.component';
import { UsallianceFedNowService } from './usalliance-fednow.service';

export interface IPlaidLinkTokenResponse {
  expiration: Date;
  link_token: string;
  request_id: string;
}

export interface IPlaidIdentifyVerifyRespose {
  identityResponse: { shareable_url: string };
  linkResponse: IPlaidLinkTokenResponse;
}

interface IPlaidLinkTokenCreate {
  P: {};
  R: IPlaidLinkTokenResponse;
}

interface IPlaidLinkTokenUpdateResponse {
  data: IPlaidLinkTokenResponse;
  success: boolean;
  message: string;
}

interface IPlaidReconnect {
  P: {};
  R: IPlaidLinkTokenResponse;
}

export interface IPlaidAccount {
  _id: string;
  access_token: string;
  account_id: string;
  institution: string;
  plaid: any;
  // verification_status from Plaid
  status:
    | 'verified' // Original status is null if verified
    | 'pending_automatic_verification'
    | 'automatically_verified'
    | 'manually_verified'
    | 'verification_expired'
    | 'verification_failed'
    | 'pending_manual_verification';

  details?: {
    mask: string;
    type: string;
  };
}

interface IPlaidGetAccounts {
  P: {};
  R: { accounts: IPlaidAccount[]; healthcheck: boolean };
}

interface IPlaidAuthorize {
  P: { access_token: string };
  R: { accounts: any; numbers: any };
}

interface IPlaidItemRemove {
  P: { bankId: string };
  R: { message: string };
}

interface IPlaidOnAddedNewBank {
  P: {
    token: string;
    account_id: string;
    institution: string;
    accountType: string;
  };
  R: {
    data: {
      access_token: string;
      item_id: string;
      request_id: string;
    };
  };
}

export interface IPlaid {
  authorize: IPlaidAuthorize;
  itemRemove: IPlaidItemRemove;
  onAddedNewBank: IPlaidOnAddedNewBank;
  plaidAccounts: IPlaidAccount[];
}

interface IGetAvailableBalances {
  P: { accountIds: string[] };
  R: { accounts: { accountId: string; balance: number; token: string }[] };
}

export interface ITransferAccounts {
  id: string | number;
  institution: string;
  institutionId: string;
  accountType: string;
  accountMask: string;
  accountNumber: string;
  routingNumber: string;
  active: boolean;
  balance: number;
  plaid?: any;
  isFedNow?: boolean;
}

export interface IAchAccount extends IPlaidAccount {
  balance?: number;
  isFedNow?: boolean;
}

const HEADER_HIDE_LOADER = 'X-No-Loader';

@Injectable({ providedIn: 'root' })
export class PlaidService {
  baseUrl: string;

  private readonly accountsSubject = new BehaviorSubject<IPlaidGetAccounts['R']>({ accounts: [], healthcheck: true });
  get accounts(): Observable<IPlaidGetAccounts['R']> {
    return this.accountsSubject.asObservable();
  }

  constructor(
    private readonly usaFedNowService: UsallianceFedNowService,
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: IAppConfig
  ) {
    this.baseUrl = `${this.config.apiUrl}/bank/plaid`;
  }

  linkTokenCreate(data: IPlaidLinkTokenCreate['P']) {
    return this.http.post<IPlaidLinkTokenCreate['R']>(`${this.baseUrl}/link-token`, data);
  }

  linkTokenUpdate(accessToken: string) {
    return this.http.put<IPlaidLinkTokenUpdateResponse>(`${this.baseUrl}/link-token`, { accessToken });
  }

  beginVerification(verificationType: VerificationType) {
    return this.http.post(`${this.baseUrl}/identity`, { verificationType });
  }

  addIdentityVerificationToDatabase(data) {
    return this.http.post(`${this.baseUrl}/record-session`, data);
  }

  retryVerification(verificationType: VerificationType) {
    return this.http.post<{ message: string; reset: boolean }>(`${this.baseUrl}/retry`, { verificationType });
  }

  checkFunds(data) {
    return this.http.post(`${this.baseUrl}/checkfunds`, data);
  }

  getAvailableBalances(data: IGetAvailableBalances['P']): Observable<IGetAvailableBalances['R']> {
    return this.http.post<IGetAvailableBalances['R']>(`${this.baseUrl}/get-balance`, data);
  }

  onAddedNewBank(token, metadata) {
    const params = {
      token,
      account_id: metadata.account_id,
      institution: metadata.institution.name,
      accountType: metadata.account.subtype,
      link_session_id: metadata.link_session_id,
      public_token: metadata.public_token,
    };

    return this.http.post<IPlaidOnAddedNewBank['R']>(`${this.baseUrl}/item`, params).pipe(
      mergeMap((response: any) => {
        if (!response?.namesMatch) {
          return of(response);
        }

        return this.getAccounts().pipe(
          map((plaid) => ({
            plaid,
            newRawAccount: response.data.newRawAccount, // Assuming `newRawAccount` needs to be extracted from the response
          }))
        );
      })
    );
  }

  itemRemove(data: IPlaidItemRemove['P']) {
    return this.http.request<IPlaidItemRemove['R']>('delete', `${this.baseUrl}/item`, {
      body: data,
    });
  }

  getAccounts(hideLoaderInterceptor = false, forceRefresh = null) {
    let params = new HttpParams();
    if (forceRefresh) {
      params = params.append('forceRefresh', forceRefresh);
    }

    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;

    return this.http.get<IPlaidGetAccounts['R']>(`${this.baseUrl}/accounts`, { headers, params }).pipe(
      map(({ accounts, healthcheck }) => {
        const formattedAccounts = accounts.map((acc) => this.formatAccount(acc));
        return { accounts: formattedAccounts, healthcheck };
      }),
      tap((result) => {
        this.accountsSubject.next(result);
      })
    );
  }

  getAccountsWithBalances(): Observable<IAchAccount[]> {
    return this.accounts.pipe(
      switchMap(({ accounts }) => {
        const accountIds = accounts.map((a) => a.account_id);

        return this.getAvailableBalances({ accountIds }).pipe(
          map(({ accounts: accountsWithBalances }) => {
            const newAccounts: IAchAccount[] = accounts.map((a) => {
              const merged = accountsWithBalances.find((accBalance) => accBalance.accountId === a.account_id);
              const balance = merged?.balance || 0;

              return { ...a, balance };
            });

            return newAccounts;
          })
        );
      })
    );
  }

  /**
   * Data format for bank selection modals
   * @param plaidAccounts
   * @returns
   */
  getTransferAccounts(withFedNowSupportCheck = false): Observable<ITransferAccounts[]> {
    return this.getAccountsWithBalances().pipe(
      switchMap((accounts) => {
        const withoutCheckingFedNowSupport$ = of(this.formatAchAccountsToTransferAccounts(accounts));
        const checkFedNowSupport$ = this.checkFedNowSupport(accounts).pipe(
          map((withFedNowAccounts) => this.formatAchAccountsToTransferAccounts(withFedNowAccounts))
        );

        return withFedNowSupportCheck ? checkFedNowSupport$ : withoutCheckingFedNowSupport$;
      })
    );
  }

  private formatAchAccountsToTransferAccounts(accounts: IAchAccount[]): ITransferAccounts[] {
    return accounts.map((plaidAccount) => {
      const { account_id, institution, details, plaid, balance = null, isFedNow = false } = plaidAccount;

      const ach = plaidAccount.plaid?.accounts || [];
      const achDetails = ach.reduce(
        (a, achDetail) => {
          return achDetail.account_id === account_id
            ? { accountNumber: achDetail.account, routingNumber: achDetail.routing }
            : a;
        },
        { accountNumber: null, routingNumber: null }
      );

      const { accountNumber, routingNumber } = achDetails;
      const institutionId = plaid?.item?.institution_id ?? null;

      return {
        id: account_id,
        institution,
        institutionId,
        accountNumber,
        routingNumber,
        balance,
        isFedNow,
        accountType: details?.type,
        accountMask: details?.mask,
        active: !!plaid,
        plaid: plaidAccount,
      };
    });
  }

  checkFedNowSupport(plaidAccounts: IAchAccount[]): Observable<IAchAccount[]> {
    const accountIds = plaidAccounts.map((a) => a.account_id);
    return this.usaFedNowService.getSupportedBanks(accountIds).pipe(
      map(({ data }) => {
        const fedNowBanks = data.filter((bank) => this.usaFedNowService.isBankFedNowSupported(bank.participant));
        return { fedNowBanks };
      }),
      map(({ fedNowBanks }) => {
        return plaidAccounts.map((a) => {
          const isFedNow = !!fedNowBanks.find((bank) => bank.accountId === a.account_id);
          return { ...a, isFedNow };
        });
      })
    );
  }

  formatAccount(account: IPlaidAccount): IPlaidAccount {
    const { plaid } = account;
    const acc = plaid?.accounts?.find((item) => item.account_id === account.account_id);
    const details = {
      type: '',
      mask: '',
    };

    if (acc) {
      details.type = acc.subtype;
      details.mask = acc.mask;
    }

    return { ...account, details };
  }

  reconnect(accessToken: string) {
    return this.http.post<IPlaidReconnect['R']>(`${this.baseUrl}/reconnect`, { accessToken });
  }
}
