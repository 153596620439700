import { Inject, Injectable } from '@angular/core';
import { Amplify, PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PubSubService {
  constructor(@Inject(APP_CONFIG) private readonly config: IAppConfig) {
    Amplify.addPluggable(
      new AWSIoTProvider({
        aws_pubsub_region: config.amplifyRegion,
        aws_pubsub_endpoint: `wss://${config.amplifyIotEndpoint}/mqtt`,
      }),
    );
  }

  topicSubscription(topicName: string): Observable<any> {
    return new Observable((subscriber) => {
      const pubSubObservable = PubSub.subscribe(topicName);
      const subscription = pubSubObservable.subscribe({
        next: (message) => {
          subscriber.next(message.value);
        },
        error: (err) => subscriber.error(err),
        complete: () => subscriber.complete(),
      });

      return () => {
        subscription.unsubscribe();
      };
    });
  }

  publishMessage(topicName: string, msg: any) {
    return PubSub.publish(topicName, msg);
  }
}
