import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Hub, Auth } from 'aws-amplify';
import { catchError, retry, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import {
  HelperService,
  UsersService,
  NotificationService,
  ActiveCampaignService,
  GonativeService,
  GoogleAnalyticsService,
} from './core/services';
import { APP_CONFIG, IAppConfig } from './config/config';
import { AirbrakeErrorHandler } from './airbrake-error-handler';
import { NavbarService } from './core/services/navbar.service';
import { ScriptLoaderService } from './core/services/script-loader.service';
import { PosthogService } from './core/services/posthog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'privateauto';
  showError = false;
  showSessionTimeout = false;
  isLogoutBtnClicked = false;
  isNativeApp = false;

  constructor(
    private readonly router: Router,
    @Inject('locationObject') private readonly location: Location,
    private readonly helperService: HelperService,
    private readonly usersService: UsersService,
    private readonly notificationService: NotificationService,
    private readonly errorHandler: AirbrakeErrorHandler,
    private readonly activeCampaignService: ActiveCampaignService,
    private readonly goNativeService: GonativeService,
    private readonly navbarService: NavbarService,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly scriptLoaderService: ScriptLoaderService,
    private readonly posthogService: PosthogService,
    private readonly renderer: Renderer2
  ) {
    this.showError = this.config.failed;
    if (!this.config.failed) {
      const params = new URLSearchParams(window.location.search);

      if (params.has('action') && params.get('action') === '') {
        if (params.get('action') === 'search') {
          this.router.navigate(['/search-listings']);
        } else if (params.get('action') === 'logout') {
          localStorage.clear();
          this.helperService.deleteCookies();
          this.router.navigate(['/auth/logout']);
        }
      }
    }
    const skipUrls = ['auth/login', 'auth/logout'];
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd && skipUrls.indexOf(val.url) !== -1) {
        this.activeCampaignService.trackUrlEvent(val.url).subscribe();
      }
    });
  }

  reload() {
    window.location.reload();
  }

  ngOnInit(): void {
    this.loadGTM();
    this.usersService.isLogoutClicked.subscribe((value) => {
      this.isLogoutBtnClicked = value;
    });
    this.isNativeApp = this.goNativeService.detectIfNativeApp();
    this.goNativeService.setFullscreen(false);
    this.googleAnalyticsService.gaEvent({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const loaderElement = document.getElementById('application-loader');
    if (loaderElement) {
      loaderElement.parentNode.removeChild(loaderElement);
    }
    if (!this.config.failed) {
      Hub.listen('auth', (data) => {
        switch (data.payload.event) {
          case 'signIn': {
            this.usersService
              .getCurrentProfile()
              .pipe(
                tap((user) => {
                  if (user) {
                    this.posthogService.posthog.identify(user._id, { email: user.userDetails.email });
                  }
                }),
                tap(() => {
                  if (this.goNativeService.detectIfNativeApp()) {
                    this.usersService.getUserApplicationData();
                  }
                })
              )
              .subscribe();
            const noAuthRedirect = localStorage.getItem('authRedirect') === 'disabled';
            if (noAuthRedirect === true) {
              break;
            }
            let redirect = '/search-listings';
            const queryParams = new URLSearchParams(window.location.search);
            if (queryParams.get('redirectTo')) {
              redirect = decodeURIComponent(queryParams.get('redirectTo'));
            }
            if (redirect.includes('?')) {
              this.router.navigateByUrl(redirect);
            } else {
              this.router.navigate([redirect]);
            }
            break;
          }
          case 'tokenRefresh': {
            this.usersService.getUserApplicationData();
            break;
          }
          case 'signOut': {
            if (this.usersService.blockTimeout) {
              this.usersService.blockTimeout = false;
              return;
            }
            this.usersService.clearUserData();
            this.posthogService.posthog.reset();

            if (this.isLogoutBtnClicked) {
              if (this.isNativeApp) {
                this.navbarService.hide();
                this.router.navigate(['/auth/login']);
                return;
              }
              window.location.href = this.config.websiteUrl;
              return;
            }

            const shouldShowTimeout =
              !window.location.pathname.includes('/auth/login') &&
              !window.location.pathname.includes('/auth/initialize') &&
              !window.location.pathname.includes('/verification-completed') &&
              !window.location.pathname.includes('/auth/business-account/accept-invite');
            if (shouldShowTimeout) {
              this.showSessionTimeout = true;
            }

            break;
          }
          case 'customState_failure':
          case 'cognitoHostedUI_failure':
          case 'signIn_failure': {
            this.usersService.cognitoData.subscribe((CD) => {
              if (CD.access_token) {
                return this.usersService.getCurrentProfile().subscribe((profile) => {
                  return profile;
                });
              }
            });
            break;
          }
          default:
            break;
        }
      });
      this.router.routeReuseStrategy.shouldReuseRoute = () => {
        return false;
      };
      this.router.events.subscribe((evt) => {
        if (evt instanceof NavigationEnd) {
          this.router.navigated = false;
          window.scrollTo(0, 0);
        }
      });
      if (!window.location.pathname.includes('/auth/initialize')) {
        this.usersService
          .getCurrentProfile()
          .pipe(
            retry(3),
            catchError((err) => {
              this.notificationService.notification(
                'error',
                'There was an error getting your user details. Please sign in'
              );
              this.errorHandler.handleError(err);
              return EMPTY;
            })
          )
          .subscribe();
      }
    }

    this.scriptLoaderService.initialize();
  }

  onLogout() {
    this.usersService.setIsLogoutClicked(true);
    Auth.signOut({ global: true });
  }
  loadGTM() {
    const containerId = this.config.isDealNow ? 'GTM-P9RZQGRW' : 'GTM-NF432VS';
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${containerId}');
    `;

    this.renderer.appendChild(document.head, script);
  }
}
