import { Component, Input } from '@angular/core';

interface Option {
  label: string;
  value: string | number | boolean;
}
@Component({
  templateUrl: './chip-select-readonly.component.html',
  standalone: true,
  selector: 'app-select-readonly',
  styleUrl: './chip-select-readonly.component.scss',
})
export class ChipSelectReadOnlyComponent {
  @Input()
  question: string;
  @Input()
  options: Option[] = [];
  @Input()
  value?: string | number | boolean;
}
