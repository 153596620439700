import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';


const HEADER_HIDE_LOADER = 'X-No-Loader';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  
  private readonly baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {
    this.baseUrl = `${this.config.apiUrl}/organization`;
  }

  updateBusinessProfile(data, hideLoaderInterceptor = false) {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;
    return this.http.put(`${this.baseUrl}/profile`, data, { headers });
  }

  inviteMembers(data, hideLoaderInterceptor = false) {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;
    return this.http.post(`${this.baseUrl}/user-invites`, data, { headers });
  }

  getMembers() {
    return this.http.get(`${this.baseUrl}/users`);
  }

  getInvitedMembers() {
    return this.http.get(`${this.baseUrl}/user-invites`);
  }

  updateUserInvite(data) {
    return this.http.put(`${this.baseUrl}/user-invites`, data);
  }

  updateUserOrganizationRoles(data) {
    return this.http.put(`${this.baseUrl}/user-role`, data);
  }
}
