import { Component, Input, SimpleChanges } from '@angular/core';
import { IOrganization } from 'src/app/models/organization.model';

@Component({
  selector: 'app-organization-full-logo',
  standalone: false,
  templateUrl: './organization-full-logo.component.html',
  styleUrl: './organization-full-logo.component.scss',
})
export class OrganizationFullLogoComponent {
  @Input('organization') organization: IOrganization;

  img: string;

  imgValid = false;

  ngOnChanges(changes: SimpleChanges) {
    const { organization } = changes;

    if (organization?.currentValue.logoFull) {
      this.configureImages(organization.currentValue.logoFull);
    }
  }

  configureImages(src: string) {
    this.imgValid = true;
    this.img = src;
  }
}
