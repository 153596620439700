import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SellerListingService } from 'src/app/core/services';
import { ISellerListing } from 'src/app/models/seller-listing.model';

@Component({
  selector: 'app-cash-offer-exterior-details',
  templateUrl: './cash-offer-exterior-details.component.html',
  styleUrl: './cash-offer-exterior-details.component.scss',
})
export class CashOfferExteriorDetailsComponent {
  @Input() listing: ISellerListing;
  @Output() onComplete: EventEmitter<string> = new EventEmitter<string>();

  exteriorDetailsForm: UntypedFormGroup;

  // Dropdown options
  exteriorColors: string[] = ['Black', 'White', 'Silver', 'Gray', 'Red', 'Blue', 'Green', 'Brown', 'Gold', 'Other'];

  interiorColors: string[] = ['Black', 'Gray', 'Tan', 'Brown', 'Beige', 'White', 'Other'];

  exteriorDamageOptions: string[] = [
    'None',
    'Minor Scratches',
    'Dents',
    'Paint Damage',
    'Rust',
    'Major Damage',
    'Other',
  ];

  windshieldDamageOptions: string[] = ['None', 'Small Chips', 'Cracks', 'Needs Replacement'];

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly listingService: SellerListingService,
    private readonly notificationService: NotificationService,
  ) {
    this.initializeForm();
  }

  private initializeForm() {
    this.exteriorDetailsForm = this.fb.group({
      ExteriorColor: ['', Validators.required],
      InteriorColor: ['', Validators.required],
      hasExteriorDamage: ['', Validators.required],
      needsWindshieldReplacement: ['', Validators.required],
      hasAftermarketParts: [''],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const { listing } = changes;
    if (listing.currentValue) {
      this.populateFormFromListing();
    }
  }

  populateFormFromListing() {
    if (!this.listing) return;

    this.exteriorDetailsForm.patchValue({
      ExteriorColor: this.listing.ExteriorColor,
      InteriorColor: this.listing.InteriorColor,
      hasExteriorDamage: this.listing.hasExteriorDamage,
      needsWindshieldReplacement: this.listing.needsWindshieldReplacement,
      hasAftermarketParts: this.listing.hasAftermarketParts,
    });
  }

  completeSection(sectionId: string): void {
    if (!this.exteriorDetailsForm.valid) {
      return;
    }

    const formValues = this.exteriorDetailsForm.value;

    const updatedListing = {
      ...this.listing,
      ExteriorColor: formValues.ExteriorColor,
      InteriorColor: formValues.InteriorColor,
      hasExteriorDamage: formValues.hasExteriorDamage,
      needsWindshieldReplacement: formValues.needsWindshieldReplacement,
      hasAftermarketParts: formValues.hasAftermarketParts,
    };

    this.listingService.createOrUpdateSellerListing(updatedListing).subscribe({
      next: (response) => {
        this.notificationService.notification('success', 'Exterior details updated successfully');
        this.onComplete.emit(sectionId);
      },
      error: () => {
        this.notificationService.notification('error', 'Error updating exterior details');
      },
    });
  }

  getFormValues() {
    return this.exteriorDetailsForm.value;
  }

  onExteriorColorSelect(color: string) {
    this.exteriorDetailsForm.patchValue({ ExteriorColor: color });
  }

  onInteriorColorSelect(color: string) {
    this.exteriorDetailsForm.patchValue({ InteriorColor: color });
  }

  onExteriorDamageSelect(damage: boolean) {
    this.exteriorDetailsForm.patchValue({ hasExteriorDamage: damage });
  }

  onWindshieldDamageSelect(damage: boolean) {
    this.exteriorDetailsForm.patchValue({ needsWindshieldReplacement: damage });
  }

  onAftermarketPartsSelect(parts: boolean) {
    this.exteriorDetailsForm.patchValue({ hasAftermarketParts: parts });
  }
}
