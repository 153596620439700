export enum OrganizationVerificationStatus {
  Unverified = 'unverified',
  PendingVerification = 'pending_verification',
  Verified = 'verified',
  Denied = 'denied',
}

export enum OrganizationStatus {
  Active = 'active',
  Banned = 'banned',
}

export interface IOrganization {
  _id: string;
  name?: string;
  website?: string
  logo?: string
  legalName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  tradeName?: string;
  taxId?: string;
  registrationDate?: Date;
  registrationType?: string;
  ownerId?: string;
  creatorId?: string;
  verificationStatus?: OrganizationVerificationStatus;
  status?: OrganizationStatus;
  verificationAppliedAt?: Date;
  verificationApprovedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}
