<app-modal #modal [modalConfig]="modalConfig">
  <div class="header">Summary for cash offer</div>

  <!-- Mobile Progress Bar -->
  @if (isMobileView) {
    <div>
      <div class="progress-steps-mobile px-3">
        @for (step of sections; track step; let i = $index) {
          <div class="step-item">
            <div class="progress-section">
              <div class="circle" [ngClass]="{ 'circle-blue': step.isComplete }"></div>
              @if (i < sections.length - 1) {
                <div class="line" [ngClass]="{ 'line-blue': step.isComplete }"></div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  }

  <div class="d-flex align-items-start justify-content-between w-100">
    <app-cash-offer-summary
      [listing]="listing"
      (sectionsUpdated)="onSectionsUpdated($event)"
      (modalClose)="onModalClose($event)"
    ></app-cash-offer-summary>

    <!-- Desktop View -->
    @if (!isMobileView) {
      <div>
        <div class="d-flex align-items-center p-2">
          <div class="">
            <app-listing-image
              [src]="listing?.mainImg"
              [vehicleType]="listing?.vehicleType"
              [width]="80"
              [height]="64"
            ></app-listing-image>
          </div>
          <div class="listing-details ms-3">
            <div class="title">{{ listing?.RegistrationYear }} {{ listing?.CarMake }} {{ listing?.CarModel }}</div>
            <div class="vin">VIN: {{ listing?.vinNumber }}</div>
            <div class="edit-vehicle">Edit vehicle</div>
          </div>
        </div>
        <div class="progress-steps pt-3 ps-1">
          @for (step of sections; track step; let i = $index) {
            <div class="step-item">
              <div class="progress-section">
                <div class="circle" [ngClass]="{ 'circle-blue': step.isComplete }"></div>
                @if (i < sections.length - 1) {
                  <div class="line" [ngClass]="{ 'line-blue': step.isComplete }"></div>
                }
              </div>
              <div class="step-content ms-3">
                <div class="step-title">{{ step.title }}</div>
              </div>
            </div>
          }
        </div>
      </div>
    }
  </div>
</app-modal>
