<form [formGroup]="vehicleDetailsForm" class="vehicle-details-form p-4">
  <div class="form-group mb-3">
    <label class="input-label" for="trim">Trim Level</label>
    <div ngbDropdown class="btn-dropdown w-100">
      <button
        class="form-control d-flex align-items-center justify-content-between text-start"
        ngbDropdownToggle
        [ngClass]="{ 'is-invalid': vehicleDetailsForm.get('trim').invalid && vehicleDetailsForm.get('trim').touched }"
      >
        <span class="ellipsis">{{ vehicleDetailsForm.get('trim').value || 'Select trim level' }}</span>
        <img alt="arrow down" src="assets/chevron-down.svg" class="dropdown-icon" />
      </button>
      <div ngbDropdownMenu class="dropdown-container" aria-labelledby="trimDropdown">
        @for (trim of trimOptions; track trim) {
          <button ngbDropdownItem class="dropdown px-3" (click)="onTrimSelect(trim)">
            {{ trim }}
          </button>
        }
      </div>
    </div>
  </div>

  <div class="form-group mb-3">
    <label class="input-label" for="transmission">Transmission Type</label>
    <div ngbDropdown class="btn-dropdown w-100">
      <button
        class="form-control d-flex align-items-center justify-content-between text-start"
        ngbDropdownToggle
        [ngClass]="{
          'is-invalid':
            vehicleDetailsForm.get('transmission').invalid && vehicleDetailsForm.get('transmission').touched,
        }"
      >
        <span class="ellipsis">{{ vehicleDetailsForm.get('transmission').value || 'Select transmission' }}</span>
        <img alt="arrow down" src="assets/chevron-down.svg" class="dropdown-icon" />
      </button>
      <div ngbDropdownMenu class="dropdown-container" aria-labelledby="transmissionDropdown">
        @for (transmission of transmissions; track transmission) {
          <button ngbDropdownItem class="dropdown px-3" (click)="onTransmissionSelect(transmission)">
            {{ transmission }}
          </button>
        }
      </div>
    </div>
  </div>

  <div class="form-group mb-3">
    <label class="input-label" for="engine">Engine</label>
    <div ngbDropdown class="btn-dropdown w-100">
      <button
        class="form-control d-flex align-items-center justify-content-between text-start"
        ngbDropdownToggle
        [ngClass]="{
          'is-invalid': vehicleDetailsForm.get('engine').invalid && vehicleDetailsForm.get('engine').touched,
        }"
      >
        <span class="ellipsis">{{ vehicleDetailsForm.get('engine').value || 'Select engine' }}</span>
        <img alt="arrow down" src="assets/chevron-down.svg" class="dropdown-icon" />
      </button>
      <div ngbDropdownMenu class="dropdown-container" aria-labelledby="engineDropdown">
        @for (engine of engineOptions; track engine) {
          <button ngbDropdownItem class="dropdown px-3" (click)="onEngineSelect(engine)">
            {{ engine }}
          </button>
        }
      </div>
    </div>
  </div>

  <div class="form-group mb-3">
    <label class="input-label" for="mileage">Mileage</label>
    <input
      class="form-control"
      type="number"
      formControlName="mileage"
      placeholder="Enter vehicle mileage"
      [ngClass]="{
        'is-invalid': vehicleDetailsForm.get('mileage').invalid && vehicleDetailsForm.get('mileage').touched,
      }"
    />
    <div class="mt-2">
      <label class="form-check-label" for="exactMileage"> This is the exact mileage </label>
      <div class>
        <input class="form-check-input" type="checkbox" formControlName="exactMilage" id="exactMileage" />
        <span class="form-check-label ps-2">Yes</span>
      </div>
    </div>
  </div>

  <div class="where-location my-3">Where are you located?</div>
  <div class="form-group mb-3">
    <label class="input-label" for="zip">ZIP Code</label>
    <input
      class="form-control"
      type="text"
      formControlName="zip"
      placeholder="Enter ZIP code"
      [ngClass]="{ 'is-invalid': vehicleDetailsForm.get('zip').invalid && vehicleDetailsForm.get('zip').touched }"
    />
  </div>

  <div class="d-flex justify-content-start mt-4">
    <button
      class="btn btn-primary next-btn"
      (click)="completeSection('vehicle-details')"
      [disabled]="!vehicleDetailsForm.valid"
    >
      Next
    </button>
  </div>
</form>
