import {
  Component,
  Input,
  ViewChild,
  TemplateRef,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ISellerListing } from 'src/app/models';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SellerListingService } from 'src/app/core/services';
import { CashOfferVehicleDetailsComponent } from './cash-offer-vehicle-details/cash-offer-vehicle-details.component';
import { CashOfferExteriorDetailsComponent } from './cash-offer-exterior-details/cash-offer-exterior-details.component';
import { CashOfferVehicleHistoryComponent } from './cash-offer-vehicle-history/cash-offer-vehicle-history.component';

export interface AccordianCashOfferSection {
  id: string;
  title: string;
  icon?: string;
  toggleText?: (isExpanded: boolean) => string;
  isComplete?: boolean;
  isEnabled?: boolean;
}

@Component({
  selector: 'app-cash-offer-summary',
  templateUrl: './cash-offer-summary.component.html',
  styleUrl: './cash-offer-summary.component.scss',
})
export class CashOfferSummaryComponent {
  @Input() listing: ISellerListing;
  @ViewChild('accordion') accordionComponent!: NgbAccordionDirective;
  @ViewChild('vehicleDetailsTemplate') vehicleDetailsTemplate: TemplateRef<any>;
  @ViewChild('exteriorInteriorTemplate') exteriorInteriorTemplate: TemplateRef<any>;
  @ViewChild('conditionHistoryTemplate') conditionHistoryTemplate: TemplateRef<any>;
  @ViewChild('offerEstimatesTemplate') offerEstimatesTemplate: TemplateRef<any>;
  @Output() sectionsUpdated = new EventEmitter<AccordianCashOfferSection[]>();
  @Output() modalClose = new EventEmitter<boolean>();

  @ViewChildren(CashOfferVehicleDetailsComponent)
  vehicleDetailsComponent: QueryList<CashOfferVehicleDetailsComponent>;

  @ViewChildren(CashOfferExteriorDetailsComponent)
  exteriorDetailsComponent: QueryList<CashOfferExteriorDetailsComponent>;

  @ViewChildren(CashOfferVehicleHistoryComponent)
  vehicleHistoryComponent: QueryList<CashOfferVehicleHistoryComponent>;

  sections: AccordianCashOfferSection[] = [
    {
      id: 'vehicle-details',
      title: 'Vehicle Details',
      isComplete: true,
      isEnabled: true,
    },
    {
      id: 'exterior-interior',
      title: 'Exterior and Interior Details',
      isComplete: false,
      isEnabled: false,
    },
    {
      id: 'condition-history',
      title: 'Condition and History',
      isComplete: false,
      isEnabled: false,
    },
    {
      id: 'offer-estimates',
      title: 'Offer Estimates',
      isComplete: false,
      isEnabled: false,
    },
  ];

  vehicleDetailsForm: UntypedFormGroup;
  exteriorInteriorForm: UntypedFormGroup;
  conditionHistoryForm: UntypedFormGroup;
  offerEstimatesForm: UntypedFormGroup;
  trimOptions: string[] = [];
  engineOptions: string[] = [];

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly sellerListingService: SellerListingService,
    private readonly notificationService: NotificationService,
  ) {}

  getFormForSection(sectionId: string): UntypedFormGroup {
    switch (sectionId) {
      case 'vehicle-details':
        return this.vehicleDetailsForm;
      case 'exterior-interior':
        return this.exteriorInteriorForm;
      case 'condition-history':
        return this.conditionHistoryForm;
      case 'offer-estimates':
        return this.offerEstimatesForm;
      default:
        return null;
    }
  }

  getSectionTemplate(sectionId: string): TemplateRef<any> {
    switch (sectionId) {
      case 'vehicle-details':
        return this.vehicleDetailsTemplate;
      case 'exterior-interior':
        return this.exteriorInteriorTemplate;
      case 'condition-history':
        return this.conditionHistoryTemplate;
      case 'offer-estimates':
        return this.offerEstimatesTemplate;
      default:
        return null;
    }
  }

  onSectionComplete(sectionId: string) {
    const currentSectionIndex = this.sections.findIndex((section) => section.id === sectionId);
    this.sections[currentSectionIndex].isComplete = true;

    if (this.sections[0].isComplete && this.sections[1].isComplete && this.sections[2].isComplete) {
      this.sections[3].isComplete = true;
    }

    if (currentSectionIndex < this.sections.length - 1) {
      this.sections[currentSectionIndex + 1].isEnabled = true;
      this.accordionComponent.expand(this.sections[currentSectionIndex + 1].id);
      this.accordionComponent.collapse(this.sections[currentSectionIndex].id);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { listing } = changes;
    if (listing.currentValue) {
      this.initCompleteSections();
    }
  }

  initCompleteSections(): void {
    const vehicleDetailsComplete = this.isVehicleDetailsComplete();
    const exteriorInteriorComplete = this.isExteriorInteriorComplete();
    const conditionHistoryComplete = this.isConditionHistoryComplete();
    const offerEstimatesComplete =
      vehicleDetailsComplete && exteriorInteriorComplete && conditionHistoryComplete && this.isOfferEstimatesComplete();

    this.sections = this.sections.map((section) => {
      switch (section.id) {
        case 'vehicle-details':
          return { ...section, isComplete: vehicleDetailsComplete, isEnabled: true };
        case 'exterior-interior':
          return { ...section, isComplete: exteriorInteriorComplete, isEnabled: vehicleDetailsComplete };
        case 'condition-history':
          return { ...section, isComplete: conditionHistoryComplete, isEnabled: exteriorInteriorComplete };
        case 'offer-estimates':
          return { ...section, isComplete: offerEstimatesComplete, isEnabled: conditionHistoryComplete };
        default:
          return section;
      }
    });

    this.sectionsUpdated.emit(this.sections);
  }

  isVehicleDetailsComplete() {
    if (!this.listing) return false;
    return !!(
      this.listing.Trim &&
      this.listing.Transmission &&
      this.listing.Engine &&
      this.listing.Mileage &&
      this.listing.Zip
    );
  }

  isExteriorInteriorComplete(): boolean {
    if (!this.listing) return false;
    return !!(
      this.listing.ExteriorColor &&
      this.listing.InteriorColor &&
      this.listing.hasExteriorDamage != null &&
      this.listing.needsWindshieldReplacement != null &&
      this.listing.hasAftermarketParts != null
    );
  }

  isConditionHistoryComplete(): boolean {
    if (!this.listing) return false;
    return !!(
      this.listing.accidentHistory != null &&
      this.listing.condition &&
      this.listing.tireCondition &&
      this.listing.hasWheelDamage != null &&
      this.listing.hasDrivingIssues != null &&
      this.listing.hasBeenSmoked != null &&
      this.listing.numberOfKeys != null &&
      this.listing.ownershipInfo?.ownershipType
    );
  }

  isOfferEstimatesComplete(): boolean {
    if (!this.listing) return false;
    return true;
  }

  resubmit() {
    const hasIncompleteSections = this.sections.some((section) => !section.isComplete);
    if (hasIncompleteSections) {
      const firstIncompleteSection = this.sections.find((section) => !section.isComplete);
      this.notificationService.notification(
        'error',
        `Please complete the ${firstIncompleteSection.title} section before resubmitting`,
      );

      this.accordionComponent.expand(firstIncompleteSection.id);
      return;
    }

    this.sellerListingService.resubmitCashOffer(this.listing._id).subscribe({
      next: () => {
        this.notificationService.notification(
          'success',
          this.listing.acquisitionSource ? 'Cash offer resubmitted' : 'Cash offer submitted',
        );
        this.modalClose.emit(true);
      },
    });
  }

  saveChanges() {
    const vehicleDetails = this.vehicleDetailsComponent.first?.getFormValues();
    const updatedVehicleDetails = {
      Trim: vehicleDetails.trim,
      Transmission: vehicleDetails.transmission,
      Engine: vehicleDetails.engine,
      Mileage: vehicleDetails.mileage,
      exactMilage: vehicleDetails.exactMilage,
      Zip: vehicleDetails.zip,
    };
    const exteriorDetails = this.exteriorDetailsComponent.first?.getFormValues();
    const vehicleHistory = this.vehicleHistoryComponent.first?.getFormValues();
    const finalVehicleHistory = {
      ...vehicleHistory,
      ownershipInfo: {
        titlePossession: vehicleHistory.titlePossession,
      },
    };

    const updateListing = {
      ...this.listing,
      ...updatedVehicleDetails,
      ...exteriorDetails,
      ...finalVehicleHistory,
    };

    this.sellerListingService.createOrUpdateSellerListing(updateListing).subscribe(() => {
      this.modalClose.emit();
    });
  }
}
