import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SellerListingService, SellerService } from 'src/app/core/services';
import { ISellerListing } from 'src/app/models/seller-listing.model';
import { Observable, of, catchError, map } from 'rxjs';

@Component({
  selector: 'app-cash-offer-vehicle-details',

  templateUrl: './cash-offer-vehicle-details.component.html',
  styleUrl: './cash-offer-vehicle-details.component.scss',
})
export class CashOfferVehicleDetailsComponent {
  @Input() listing: ISellerListing;
  @Output() onComplete: EventEmitter<string> = new EventEmitter<string>();

  vehicleDetailsForm: UntypedFormGroup;
  trimOptions: string[] = [];
  engineOptions: string[] = [];
  transmissions = [
    'Automatic Transmission (AT)',
    'Manual Transmission (MT)',
    'Automated Manual Transmission (AM)',
    'Continuously Variable Transmission (CVT)',
  ];

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly listingService: SellerListingService,
    private readonly sellerService: SellerService,
    private readonly notificationService: NotificationService,
  ) {
    this.initializeForm();
  }

  private initializeForm() {
    this.vehicleDetailsForm = this.fb.group({
      trim: ['', Validators.required],
      transmission: ['', Validators.required],
      engine: ['', Validators.required],
      mileage: ['', [Validators.required, Validators.min(0)]],
      exactMilage: [false],
      zip: ['', Validators.required],
    });
  }

  private getListingOtherInfo(): Observable<any> {
    let obs$ = of({});

    if (this.listing.vinNumber) {
      obs$ = this.sellerService.getInfoByVIN(this.listing.vinNumber, this.listing.vehicleType, true);
    } else if (this.listing.licencePlate) {
      obs$ = this.sellerService.getInfoByPlate(this.listing.licencePlate, this.listing.stateRegistered, true);
    }

    return obs$.pipe(
      catchError(() => {
        return of({});
      }),
    );
  }

  private getTrimOptions(): Observable<string[]> {
    if (this.trimOptions.length || this.listing?.isVintageVehicle) {
      return of(this.trimOptions);
    }

    return this.getListingOtherInfo().pipe(map(({ trimOptions = [] }) => trimOptions));
  }

  ngOnChanges(changes: SimpleChanges) {
    const { listing } = changes;
    if (listing?.currentValue) {
      this.populateFormFromListing();
      this.getTrimOptions().subscribe((options) => {
        this.trimOptions = options;
      });
    }
  }

  ngOnInit(): void {}

  populateFormFromListing() {
    if (!this.listing) return;

    this.vehicleDetailsForm.patchValue({
      trim: this.listing.Trim || '',
      transmission: this.listing.Transmission || '',
      engine: this.listing.Engine || '',
      mileage: this.listing.Mileage || '',
      exactMilage: false,
      zip: this.listing.Zip || '',
    });
  }

  getFormValues() {
    return this.vehicleDetailsForm.value;
  }

  completeSection(sectionId: string): void {
    if (!this.vehicleDetailsForm.valid) {
      return;
    }

    const formValues = this.vehicleDetailsForm.value;

    const updatedListing = {
      ...this.listing,
      Trim: formValues.trim,
      Transmission: formValues.transmission,
      Engine: formValues.engine,
      Mileage: formValues.mileage,
      exactMilage: formValues.exactMilage,
      Zip: formValues.zip,
    };

    this.listingService.createOrUpdateSellerListing(updatedListing).subscribe({
      next: (response) => {
        this.notificationService.notification('success', 'Vehicle details updated successfully');
        this.onComplete.emit(sectionId);
      },
      error: () => {
        this.notificationService.notification('error', 'Error updating vehicle details');
      },
    });
  }

  onTrimSelect(trim: string) {
    this.vehicleDetailsForm.patchValue({ trim });
  }

  onTransmissionSelect(transmission: string) {
    this.vehicleDetailsForm.patchValue({ transmission });
  }

  onEngineSelect(engine: string) {
    this.vehicleDetailsForm.patchValue({ engine });
  }
}
