import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { CustomDateParserFormatter } from 'src/app/core/services/ngb-datepicker-custom.service';
import { isValidDate } from 'src/app/core/validators/date';
import { MapLocationModel } from 'src/app/models';
import STATES from 'src/app/statics/states-hash';
import { PlacesAutocompleteInput } from 'src/app/widgets/places-autocomplete-input/places-autocomplete-input.component';

@AutoUnsubscribe()
@Component({
  selector: 'app-edit-information',
  templateUrl: './edit-information.component.html',
  providers: [{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }],
  styleUrls: ['../prove-verification-modal/prove-verification-modal.component.scss'],
})
export class EditInformationComponent implements OnInit, OnDestroy {
  @ViewChild('autocompleteInput') autocompleteInput: PlacesAutocompleteInput;
  @Output() onContinue = new EventEmitter();
  @Input() form: FormGroup;
  @Input() showError: boolean;
  @Input() disablePrimaryFields: boolean;
  isEditing = false;
  submitted = false;
  readonly states = STATES;
  isFullAddressVisible = false;
  location: MapLocationModel;

  ngOnInit(): void {
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onVerify() {
    this.submitted = true;
    if (!this.disablePrimaryFields) {
      if (this.form.value.birthDate && !isValidDate(this.form.value.birthDate)) {
        this.form.get('birthDate').setErrors({ message: 'Invalid date format' });
      }
      // if (!this.form.value.ssn || this.form.value.ssn.length < 9) {
      //   this.form.get('ssn').setErrors({ message: 'Invalid SSN' });
      // }
    }
    if (this.form.invalid) {
      return;
    }
    this.onContinue.emit();
  }

  get f() {
    return this.form.controls;
  }

  shouldShowFullAddress() {
    return (
      !this.form.get('address').value &&
      !this.form.get('city').value &&
      !this.form.get('region').value &&
      !this.form.get('postalCode').value
    );
  }

  updateLocation(location: MapLocationModel) {
    this.form.patchValue({
      address: location.address,
      extendedAddress: '',
      city: location.city,
      region: location.stateShortname,
      postalCode: location.zipcode,
    });
  }
}
