import ChatKitty, {
  UserSession,
  Channel,
  TextUserMessage,
  Message,
  ChatKittyFailedResult,
  Notification,
  ChatKittyPaginator,
} from '@chatkitty/core';
import _ from 'lodash';
import { Inject, Injectable } from '@angular/core';
import { NotificationService } from 'src/app/core/services/notification.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, from, of, EMPTY, Subject, firstValueFrom } from 'rxjs';
import { catchError, concatMap, filter, finalize, switchMap, take, timeout, toArray } from 'rxjs/operators';
import {
  ChannelStatus,
  ChatMessage,
  ChatMessageTypes,
  DealChangedUrl,
  IChatBody,
  IChatNavItem,
} from 'src/app/marketing-inbox/marketing-inbox.model';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { Location } from '@angular/common';
import { AirbrakeErrorHandler } from 'src/app/airbrake-error-handler';
import { IDeal } from 'src/app/deals/deals.models';
import { IUser } from '../../models/user.model';
import { LoadingService } from './loading.service';
import { PosthogService } from './posthog.service';
import { PostHogFeatureFlags } from 'src/third-party-integrations/posthog';

interface IGetChatDetails {
  P: { channelId: number; userId: string; listingId: string; status: ChannelStatus }[];
  R: { channels: IChatNavItem[] };
}

@Injectable({
  providedIn: 'root',
})
export class ChatKittyService {
  public onCountedUnread?: (count) => void;
  public onUpdateNewMsg?: (id: number, message?: string, time?: string) => void = () => {};
  public static inSession = false;
  public kitty = new ChatKitty({ apiKey: this.config.chatKittyApiKey });
  baseUrl = '';
  messagePaginator: null | ChatKittyPaginator<Message>;
  messageNextPage = false;
  channelPaginator: null | ChatKittyPaginator<Channel>;
  channelNextPage = false;
  unreadChannels: Set<number> = new Set<number>();
  userProfile: IUser;
  isSessionStarting = false;
  refreshCounter = 0;

  private readonly chatKittyDataSubject = new BehaviorSubject<UserSession>(null);
  get chatKittyData() {
    return this.chatKittyDataSubject.asObservable().pipe(filter(Boolean)) as Observable<UserSession>;
  }

  private readonly changedUrlSubject = new BehaviorSubject<DealChangedUrl>(null);
  get changedUrl() {
    return this.changedUrlSubject
      .asObservable()
      .pipe(filter(({ url }) => url.includes('/inbox'))) as Observable<DealChangedUrl>;
  }

  refreshConnection(refreshCount: number): void {
    if (this.kitty?.currentUser) {
      return;
    }
    if (refreshCount > 3) {
      this.handleChatkittyError('ChatConnectionFailure');
      return;
    }
    this.refreshKitty(refreshCount);
  }

  refreshKitty(refreshCount: number) {
    this.kitty.endSession();
    this.handleChatkittyError('ChatKittyConnectionError');
    const chatParams =
      refreshCount > 1
        ? { apiKey: this.config.chatKittyApiKey, host: this.config.chatKittyHost }
        : { apiKey: this.config.chatKittyApiKey };
    this.kitty = new ChatKitty(chatParams);
    this.refreshCounter += 1;
    this.startUserSession(this.userProfile, true);
  }

  constructor(
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    private readonly notificationService: NotificationService,
    private readonly http: HttpClient,
    private readonly location: Location,
    private readonly errorHandler: AirbrakeErrorHandler,
    private readonly loadingService: LoadingService,
    private readonly posthogService: PosthogService,
  ) {
    // Hack because params in activatedRoute isn't updated when location.go is called.
    location.onUrlChange((url, state) => {
      const queryParametersIdx = url.indexOf('?');
      let queryParameters = {};
      if (queryParametersIdx > 0) {
        const queryParametersStr = url.substring(queryParametersIdx + 1, url.length);
        queryParameters = queryParametersStr
          .split('&')
          .map((params) => params.split('='))
          .reduce((a, [k, v]) => ({ ...a, [k]: v }), {});
      }

      this.changedUrlSubject.next({ url, state, queryParameters });
    });

    this.baseUrl = `${this.config.apiUrl}/chat`;
  }

  startUserSession(userProfile: IUser, force = false) {
    if (!userProfile?._id) {
      return;
    }
    const chatKittyDataIsAvailable = this.chatKittyDataSubject.getValue() || this.isSessionStarting;

    this.userProfile = userProfile;
    if (chatKittyDataIsAvailable && !force) {
      return;
    }

    this.isSessionStarting = true;
    from(
      this.kitty.startSession({
        username: userProfile?._id,
      }),
    )
      .pipe(
        timeout(7500),
        take(1),
        finalize(() => {
          this.isSessionStarting = false;
        }),
      )
      .subscribe({
        next: (result) => {
          if ('session' in result) {
            this.configureListeners();
            this.updateCurrentUser(userProfile);
            this.getCurrentChannels();
            this.chatKittyDataSubject.next(result.session);
          }

          if ('error' in result) {
            this.chatKittyDataSubject.next(null);
            this.refreshConnection(this.refreshCounter + 1);
          }
        },
        error: (err) => {
          this.refreshConnection(this.refreshCounter + 1);
        },
      });
  }

  updateCurrentUser(userProfile: IUser) {
    return from(
      this.kitty.updateCurrentUser((user) => {
        const { profileImage, shortName } = userProfile.userDetails;
        const displayName = shortName ? `${shortName}` : 'User name not set';
        const verification = userProfile.verification;

        return {
          ...user,
          displayName,

          properties: {
            verification,
            // Saving image here as displayPictureUrl isn't updating successfully
            profileImage,
          },
        };
      }),
    );
  }

  configureListeners() {
    this.kitty.onNotificationReceived((notification: Notification) => {
      this.onUpdateNewMsg(notification.channel.id, notification.body, notification.createdTime);
      this.getCurrentChannels();
    });
  }

  public createPrivateChannel(sellerId: string, buyerId: string, listingId: string): Observable<any> {
    return from(
      this.kitty.createChannel({
        type: 'PRIVATE',
        members: [{ username: sellerId }],
        name: `${listingId}-${buyerId}`,
        properties: {
          listingId,
          sellerId,
          buyerId,
          status: 'active',
        },
      }),
    ).pipe(
      switchMap((result) => {
        if ('channel' in result) {
          return of(result.channel);
        }
        if ('error' in result) {
          if (result.error.error === 'ChannelDuplicateNameException') {
            return this.recreateChannel(buyerId, listingId, sellerId);
          }
          this.handleChatkittyError(result);
        }
        return of(null);
      }),
      catchError((e) => {
        this.handleChatkittyError(e);
        return EMPTY;
      }),
    );
  }

  handleChatkittyError(result: ChatKittyFailedResult | string) {
    let errorType = '';
    if (typeof result === 'string') {
      errorType = result;
    } else {
      const {
        error: { error: errorType, message },
      } = result;
    }
    if (this.location.path().includes('/inbox')) {
      switch (errorType) {
        case 'ChannelDuplicateNameException':
          this.notificationService.notification(
            'error',
            'Unable to message the user at this time. Please contact support if this problem persists.',
          );
          break;
        case 'ChatKittyConnectionError':
          this.notificationService.notification('info', 'Attempting to recconect to chat');
          break;
        case 'ChatUserNotFoundException':
        case 'NoActiveSessionError':
        default:
          this.notificationService.notification(
            'error',
            'An error has occured with the chat. Contact support if the problem persists. ',
          );
          break;
      }
    }
    this.errorHandler.handleError(result);
  }

  public alertNav(msg) {
    this.onUpdateNewMsg(msg.channelId, msg.body, msg.createdTime);
  }

  public async getMessages(channel): Promise<ChatMessage[]> {
    const result = await this.kitty.listMessages({
      channel,
    });
    if ('paginator' in result) {
      this.messageNextPage = result.paginator.hasNextPage;
      if (this.messageNextPage) {
        this.messagePaginator = result.paginator;
      }
      return result.paginator.items.map((message) => this.processChatKittyMessage(message));
    }

    if ('error' in result) {
      this.handleChatkittyError(result);
    }
    return [];
  }

  get hasMoreMessages(): boolean {
    return this.messageNextPage;
  }

  get hasMoreChannels(): boolean {
    return this.channelNextPage;
  }

  public async getMoreMessage(): Promise<ChatMessage[]> {
    let toReturn = [];
    if (this.messageNextPage) {
      this.messagePaginator = await this.messagePaginator.nextPage();
      this.messageNextPage = this.messagePaginator.hasNextPage;
      toReturn = toReturn.concat(this.messagePaginator.items.map((message) => this.processChatKittyMessage(message)));
    }
    return toReturn;
  }

  public async getMoreChannels(): Promise<Channel[]> {
    let toReturn = [];
    if (this.channelNextPage) {
      this.channelPaginator = await this.channelPaginator.nextPage();
      this.channelNextPage = this.channelPaginator.hasNextPage;
      toReturn = toReturn.concat(this.channelPaginator.items);
    }
    return toReturn;
  }

  processChatKittyMessage(message: Message): ChatMessage {
    const chatMessage = message as ChatMessage;
    const temporaryMessage: TextUserMessage = message as TextUserMessage;
    try {
      chatMessage.bodyObject = JSON.parse(temporaryMessage.body);
    } catch (err) {
      chatMessage.bodyObject = {
        msgType: temporaryMessage.type,
        message: temporaryMessage.body,
      };
    }

    return chatMessage;
  }

  public async getCurrentChannels(updateUnread = true): Promise<Channel[]> {
    const result = await this.kitty.listChannels({ filter: { joined: true } });

    if ('paginator' in result) {
      if (updateUnread) {
        this.getUnreadChannels(result.paginator.items);
      }
      this.channelPaginator = result.paginator;
      const allItems: Channel[] = result.paginator.items;
      this.channelNextPage = result.paginator.hasNextPage;
      return allItems;
    }

    if ('error' in result) {
      this.handleChatkittyError(result);
    }

    return [];
  }

  public async hasChannelBeenRead(channel: Channel): Promise<boolean> {
    const result = await this.kitty.checkChannelUnread({
      channel,
    });
    if ('unread' in result) {
      return result.unread;
    }
    if ('error' in result) {
      this.handleChatkittyError(result);
    }
    return false;
  }

  public async readChannel(channel: Channel) {
    const result = await this.kitty.readChannel({ channel });
    this.unreadChannels.delete(channel.id);
    this.onCountedUnread(this.unreadChannels.size);
    if ('error' in result) {
      this.handleChatkittyError(result);
    }
  }

  public async getUnreadChannels(channels: Channel[]) {
    this.unreadChannels.clear();
    channels.forEach(
      this.delayLoop(
        (channel: Channel) =>
          this.hasChannelBeenRead(channel).then((unread) => {
            if (unread) {
              this.unreadChannels.add(channel.id);
              this.onUpdateNewMsg(channel.id);
            }
            this.onCountedUnread(this.unreadChannels.size);
          }),
        100,
      ),
    );
  }

  public closeUserSession() {
    this.kitty.endSession();
  }

  private async joinChannel(channel: Channel) {
    const result = await this.kitty.joinChannel({ channel });
    if ('error' in result) {
      this.handleChatkittyError(result);
    }
  }

  public async joinChannelByName(channelName: string) {
    const result = await this.kitty.listChannels({
      filter: { joined: false, name: channelName },
    });

    if ('paginator' in result) {
      const channels = result.paginator.items;
      if (channels.length === 1) {
        const ch = channels[0];
        this.joinChannel(ch);
        return ch;
      }
    }

    if ('error' in result) {
      this.handleChatkittyError(result);
    }

    return null;
  }

  public async getChannel(id: number | string): Promise<Channel | null> {
    let channelId = id;

    if (typeof channelId === 'string') {
      channelId = parseInt(channelId, 10);
    }

    const result = await this.kitty.retrieveChannel(channelId);
    if ('channel' in result) {
      return result.channel;
    }

    return null;
  }

  async sendMessage(message: IChatBody, rawChannel: Channel | number | string, useLoader = true) {
    if (useLoader) {
      this.loadingService.setLoading(true);
    }
    const jsonMessage = JSON.stringify(message);
    let channel = rawChannel;

    if (typeof channel === 'string') {
      channel = parseInt(channel, 10);
    }

    if (typeof channel === 'number') {
      channel = await this.getChannel(channel);
    }

    const { status } = channel.properties as any;
    if (status !== ChannelStatus.active) {
      await firstValueFrom(this.setChannelStatus(channel.id, ChannelStatus.active));
    }

    this.kitty.sendMessage({
      channel,
      body: jsonMessage,
    });

    await new Promise((resolve) => setTimeout(resolve, 500));
    if (useLoader) {
      this.loadingService.setLoading(false);
    }
  }

  sendTestDriveMessage(testDrive, isEvent = false, notifications = null) {
    const { _id, chatId } = testDrive;
    const getChannel$ = from(this.getChannel(chatId));
    const msgType = isEvent ? ChatMessageTypes.TEST_DRIVE_EVENT : ChatMessageTypes.TEST_DRIVE;

    return getChannel$.pipe(
      switchMap((channel) => {
        const msg = {
          msgType,
          message: _id,
          chatId,
          notifications,
          useDealNow: this.config.isDealNow,
        };
        return from(this.sendMessage(msg, channel));
      }),
    );
  }

  sendOfferMessage(offer, isEvent = false, notifications = null) {
    const { _id, chatId } = offer;
    const getChannel$ = from(this.getChannel(chatId));
    const msgType = isEvent ? ChatMessageTypes.OFFER_EVENT : ChatMessageTypes.OFFER;

    return getChannel$.pipe(
      switchMap((channel) => {
        const msg = {
          msgType,
          message: _id,
          chatId,
          notifications,
          useDealNow: this.config.isDealNow,
        };
        return from(this.sendMessage(msg, channel));
      }),
    );
  }

  sendDealMessage(deal: IDeal, message, msgType = ChatMessageTypes.DEAL, data = null) {
    const dealId = deal._id;
    const chatId = parseInt(deal.offer.chatId, 10);
    const getChannel$ = from(this.getChannel(chatId));

    return getChannel$.pipe(
      switchMap((channel) => {
        const msg = {
          msgType,
          message,
          chatId,
          data: { ...data, dealId },
          useDealNow: this.config.isDealNow,
        };
        return from(this.sendMessage(msg, channel, false));
      }),
    );
  }

  sendInspectionMessage(inspectionId: string, chatId: number) {
    const msgType = ChatMessageTypes.INSPECTION;
    const getChannel$ = from(this.getChannel(chatId));
    return getChannel$.pipe(
      switchMap((channel) => {
        const msg = {
          msgType,
          message: inspectionId,
          chatId,
          useDealNow: this.config.isDealNow,
        };
        return from(this.sendMessage(msg, channel));
      }),
    );
  }

  sendShippingMessage(shippingId: string, chatId: number) {
    const msgType = ChatMessageTypes.SHIPPING;
    const getChannel$ = from(this.getChannel(chatId));
    return getChannel$.pipe(
      switchMap((channel) => {
        const msg = {
          msgType,
          message: shippingId,
          chatId,
          useDealNow: this.config.isDealNow,
        };
        return from(this.sendMessage(msg, channel));
      }),
    );
  }

  sendSystemMessage(message: string, channelId: number) {
    return this.http.post(`${this.config.apiUrl}/chat/system-message`, { channelId, message });
  }

  async sendDocMessages(chatIds: string[], documentId: string): Promise<any[]> {
    const msgType = ChatMessageTypes.DOCUMENT;

    // Use concatMap to ensure each message is sent in sequence
    const sendMessages$ = from(chatIds).pipe(
      concatMap(async (idString) => {
        const chatId = parseInt(idString, 10);
        const channel = await this.getChannel(chatId);
        const msg = {
          msgType,
          message: documentId,
          chatId,
          useDealNow: this.config.isDealNow,
        };
        return this.sendMessage(msg, channel);
      }),
      toArray(),
    );

    return sendMessages$.toPromise();
  }

  async leaveChannel(chatId: number): Promise<boolean> {
    const channelResult = await this.kitty.retrieveChannel(chatId);
    if ('channel' in channelResult) {
      if (channelResult.channel.type === 'PRIVATE') {
        return this.kitty.leaveChannel({ channel: channelResult.channel }).then((leaveResult) => {
          return leaveResult.succeeded;
        });
      }
      if (channelResult.channel.type === 'DIRECT' && 'members' in channelResult.channel) {
        return this.kitty.hideChannel({ channel: channelResult.channel }).then((hideResult) => {
          return hideResult.succeeded;
        });
      }
    } else {
      return channelResult.succeeded;
    }
    return false;
  }

  async channelIdByName(buyerId: string, listingId: string): Promise<number | null> {
    const result = await this.kitty.listChannels({ filter: { joined: true, name: `${listingId}-${buyerId}` } });
    if ('paginator' in result && result.paginator.items[0]) {
      return result.paginator.items[0].id;
    }
    return null;
  }

  async channelIdAndPropertiesByName(
    buyerId: string,
    listingId: string,
  ): Promise<{ id: number | null; properties: any } | null> {
    const result = await this.kitty.listChannels({ filter: { joined: true, name: `${listingId}-${buyerId}` } });
    if ('paginator' in result && result.paginator.items[0]) {
      return { id: result.paginator.items[0].id, properties: result.paginator.items[0].properties };
    }
    return { id: null, properties: null };
  }

  setChannelStatus(channelId: number, status: ChannelStatus) {
    return this.http.post(`${this.config.apiUrl}/chat/status`, { channelId, status });
  }

  approveMessages(channelId: number) {
    return this.http.post(`${this.config.apiUrl}/chat/approve`, { channelId });
  }

  recreateChannel(buyerId: string, listingId: string, sellerId: string) {
    return this.http.post(`${this.config.apiUrl}/chat/recreate`, { buyerId, listingId, sellerId });
  }

  getChatDetails(data: IGetChatDetails['P']) {
    return this.http.post<IGetChatDetails['R']>(`${this.baseUrl}/details`, data);
  }

  delayLoop(fn: (ch: Channel) => void, delay: number) {
    return (ch: Channel, i: number) => {
      setTimeout(() => {
        fn(ch);
      }, i * delay);
    };
  }

  async getChannelCreationAllowed() {
    const chatLimit = this.posthogService.posthog.getFeatureFlagPayload(PostHogFeatureFlags.ChatCreationLimit);

    if (!chatLimit || this.userProfile.chatCreationAllowed) {
      return true;
    }

    const partiallyVerified = this.userProfile.verification.vouched;

    const limitValue = partiallyVerified ? chatLimit['partially-verified'] : chatLimit['unverified'];

    const result = await this.kitty.listChannels({});
    if ('paginator' in result && result.paginator.items) {
      const createdChannels = result.paginator.items.filter((ch) => ch.creator?.name === this.userProfile?._id);
      return createdChannels.length < limitValue;
    }
    return false;
  }
}
