import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { PosthogService } from '../services/posthog.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagGuard {
  constructor(private router: Router, private readonly posthogService: PosthogService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const requiredFeature = route.data['requiredFeature'] as string;

    if (!requiredFeature) {
      return true; // No feature flag required, allow access
    }

    if (this.posthogService.posthog.isFeatureEnabled(requiredFeature)) {
      return true;
    }

    return this.router.createUrlTree(['/deals']); 
  }
}