import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IChatUser } from 'src/app/marketing-inbox/marketing-inbox.model';
import { IUser } from 'src/app/models';

@Injectable({
  providedIn: 'root',
})
export class VerificationService {
  verificationPopoverSubject: Subject<boolean> = new Subject<boolean>();
  constructor() {}

  getVerificationDetails<T extends IUser | IChatUser>(user: T): T {
    if (!user) {
      return null;
    }
    const { userDetails, verification } = user;
    const isEmailVerified = user?.verification?.email;
    const isPhoneVerified = verification ? !!verification.phone : false;
    const isLicenseVerified = verification ? !!verification.vouched : false;
    let isBankConnected = !!(userDetails?.agreementNumber || verification?.bank);
    if ('organization' in user) {
      isBankConnected = user.organization?.financialAccounts?.length > 0;
    }
    const isVerified = !!(isEmailVerified && isPhoneVerified && isLicenseVerified && isBankConnected);
    const isUserPartiallyVerified = !!(isEmailVerified && isPhoneVerified && isLicenseVerified);
    const setUser = this.setShortName(user);
    return {
      ...setUser,
      verification: {
        ...verification,
        isEmailVerified,
        isPhoneVerified,
        isLicenseVerified,
        isBankConnected,
        isVerified,
        isUserPartiallyVerified,
      },
    };
  }

  setShortName<T extends IUser | IChatUser>(user: T): T {
    const { firstName, lastName, nickname } = user.userDetails;

    const shortName = nickname
      ? `${nickname} ${lastName?.charAt(0)}`
      : firstName && lastName
        ? `${firstName} ${lastName.charAt(0)}.`
        : null;

    return { ...user, userDetails: { ...user.userDetails, shortName } };
  }

  showVerificationPopover() {
    this.verificationPopoverSubject.next(true);
  }

  get verificationPopoverVisible(): Observable<boolean> {
    return this.verificationPopoverSubject;
  }
}
