import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IBalance } from 'src/app/models/financial-account.model';

@Injectable({ providedIn: 'root' })
export class BalanceService {
  private readonly balanceSubject = new BehaviorSubject<IBalance>(null);

  get balance$() {
    return this.balanceSubject.asObservable();
  }

  setBalance(balance: IBalance) {
    this.balanceSubject.next(balance);
  }
}
