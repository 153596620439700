import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { map, catchError, filter, switchMap, pluck, tap, distinctUntilChanged } from 'rxjs/operators';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { HelperService } from 'src/app/services/helper.service';
import { AirbrakeErrorHandler } from 'src/app/airbrake-error-handler';
import { IReciverNotifications } from 'src/app/marketing-inbox/marketing-inbox.model';
import { CookieService } from 'ngx-cookie-service';
import { isEqual } from 'lodash';
import { IUser, IUserCognito, User, IFavItem, ISellerListingWithUser } from '../../models';
import { ChatKittyService } from './chatKitty.service';
import { SellerListingService } from './seller-listing.service';
import { UsallianceAccountStatuses, UsallianceLoanStatuses, UsallianceService } from './usalliance.service';
import { PlaidService } from './plaid.service';
import { LoanService } from './loan.service';
import { VerificationService } from './verification.service';
import { NotificationService } from './notification.service';
import { AvailedServiceVehicleStats } from 'src/app/statics/additional-services';
import { AuthorizationService } from './authorizaton.service';

const HEADER_HIDE_LOADER = 'X-No-Loader';

@Injectable({ providedIn: 'root' })
export class UsersService {
  private readonly userSubject = new BehaviorSubject<IUser>(null);
  private readonly userFavSubject = new BehaviorSubject<ISellerListingWithUser[]>([]);
  private readonly cognitoDataSubject = new BehaviorSubject<any>({
    accessToken: '',
    idToken: '',
    exp: null,
  });

  private readonly baseUrl: string;
  private favIds: string[] = [];
  private favs: ISellerListingWithUser[] = [];

  public blockTimeout: boolean = false;

  constructor(
    private readonly http: HttpClient,
    private readonly chatKittyService: ChatKittyService,
    private readonly sellerListingService: SellerListingService,
    private readonly usallianceService: UsallianceService,
    private readonly helperService: HelperService,
    private readonly plaidService: PlaidService,
    private readonly loanService: LoanService,
    private readonly verificationService: VerificationService,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
    private readonly errorHandler: AirbrakeErrorHandler,
    private readonly cookieService: CookieService,
    private readonly notifService: NotificationService,
    private readonly authorizationService: AuthorizationService
  ) {
    this.baseUrl = `${this.config.apiUrl}/users`;
  }

  private readonly isLogoutBtnClicked = new BehaviorSubject<boolean>(false);

  get isLogoutClicked() {
    return this.isLogoutBtnClicked.asObservable();
  }

  get cognitoData(): Observable<any> {
    return this.cognitoDataSubject.asObservable();
  }

  get user(): Observable<IUser> {
    return this.userSubject.asObservable().pipe(filter(Boolean)) as Observable<IUser>;
  }

  get favorites() {
    return this.userFavSubject.asObservable();
  }

  get userStateWithNull() {
    return this.userSubject.asObservable();
  }

  setIsLogoutClicked(value: boolean) {
    this.isLogoutBtnClicked.next(value);
  }

  me(hideLoaderInterceptor = false) {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;
    return this.http.get<IUser>(`${this.baseUrl}/me`, { headers });
  }

  getUser = (id: string) =>
    this.http
      .get<IUser>(`${this.baseUrl}/${id}`)
      .pipe(map((responseData) => this.verificationService.getVerificationDetails(responseData)));

  updateEmail(data) {
    return this.http.put(`${this.baseUrl}/email`, data);
  }

  updateDriverLicense(data) {
    return this.http.put(`${this.baseUrl}/license`, data);
  }

  updateNotifications(data) {
    const headers = new HttpHeaders().set(HEADER_HIDE_LOADER, '1');
    return this.http.put(`${this.baseUrl}/notifications`, data, { headers });
  }

  updateVerification(data, hideLoaderInterceptor = false) {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;
    return this.http.put(`${this.baseUrl}/verification`, data, { headers });
  }

  updateProfile(data, hideLoaderInterceptor = false) {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;
    return this.http.put(`${this.baseUrl}/profile`, data, { headers });
  }

  updateInitialReferrer(initialReferrer) {
    return this.http.post(`${this.baseUrl}/initial-referrer`, { initialReferrer });
  }

  updatePhone(data) {
    return this.http.post<any>(`${this.baseUrl}/phone`, data);
  }

  verifyPhone(data) {
    return this.http.put<any>(`${this.baseUrl}/phone`, data);
  }

  forgotPassword(email: string) {
    return this.http.post<any>(`${this.baseUrl}/forgot-password`, { email });
  }

  resetPassword(params: { token: string; email: string; password: string }) {
    return this.http.put<any>(`${this.baseUrl}/reset-password`, params);
  }

  validateResetPassword(token: string) {
    return this.http.get(`${this.baseUrl}/validate-reset-password?token=${token}`, {});
  }

  confirmPasswordReset() {
    return this.http.get(`${this.baseUrl}/confirm-reset-password`);
  }

  resendPhoneCode() {
    return this.http.get<any>(`${this.baseUrl}/phone`);
  }

  confirmEmail(data: { code: string }) {
    return this.http.post<any>(`${this.baseUrl}/confirm-email`, data);
  }

  sendVerification() {
    return this.http.post<any>(`${this.baseUrl}/email/send-verification`, {});
  }

  checkIfUserAccessListingReports(listingId) {
    return this.http.get(`${this.baseUrl}/reports/${listingId}`);
  }

  getCurrentProfile(hideLoaderInterceptor = false) {
    return from(Auth.currentSession()).pipe(
      switchMap(() => {
        return this.me(hideLoaderInterceptor);
      }),
      switchMap((user) => {
        return this.getAwsProfile().pipe(map((cognito) => ({ ...user, cognito })));
      }),
      switchMap((user) => this.configureUser(user)),
      map((user: User) => {
        if (user.verification?.bank !== user.verification.isBankConnected) {
          this.updateVerification(
            {
              bank: user.verification.isBankConnected,
            },
            hideLoaderInterceptor
          ).subscribe();
        }

        this.authorizationService.updateAbility(user.permissions);
        this.userSubject.next(user);
        this.chatKittyService.startUserSession(user);
        this.getFavs(hideLoaderInterceptor)
          .pipe(
            pluck('data'),
            map((favs: { _id: string; favorites: IFavItem[] }) => {
              this.favIds = favs?.favorites.map((fav) => fav.listingId?._id);
              this.favs = favs?.favorites.map((fav) => fav.listingId).filter((fav) => !!fav);
              return this.userFavSubject.next(this.favs);
            })
          )
          .subscribe();
        return user;
      }),
      catchError((err) => {
        this.userSubject.next(null);
        this.cognitoDataSubject.next({ accessToken: '', idToken: '', exp: null });
        if (err !== 'No current user') {
          this.errorHandler.handleError(err);
        }
        return of(null);
      })
    );
  }

  getAwsProfile() {
    return this.cognitoData.pipe(
      switchMap((cognitoData) => {
        const hostedUiData = this.getHostedUIData();
        if (hostedUiData.accessToken) {
          this.cognitoDataSubject.next(hostedUiData);
        }
        if (cognitoData?.accessToken) {
          return this.cognitoData;
        }
        return new Observable((observer) => {
          const abortController = new AbortController();
          const subscription = from(Auth.currentAuthenticatedUser({ bypassCache: true })).subscribe(observer);

          return () => {
            abortController.abort();
            subscription.unsubscribe();
          };
        });
      })
    ) as Observable<IUserCognito>;
  }

  clearUserData() {
    localStorage.clear();
    this.helperService.deleteCookies();
    this.userSubject.next(null);
    this.chatKittyService.closeUserSession();
    this.sellerListingService.clearListingCache();
    this.cookieService.deleteAll('/', this.config.appDomain);
  }

  getUserApplicationData() {
    this.user
      .pipe(
        distinctUntilChanged((prevUser, currUser) => {
          return !isEqual(prevUser, currUser);
        }),
        tap((user: IUser) => {
          this.chatKittyService.refreshCounter = 0;
          this.chatKittyService.startUserSession(user);
        }),
        tap((user: IUser) => {
          this.sellerListingService.retrieveSellerListings(true).subscribe();
        }),
        tap(() => {
          this.plaidService.getAccounts(true).subscribe();
        }),
        switchMap((user) => {
          if (user.userDetails.personNumber && user.userDetails.agreementNumber) {
            return this.usallianceService.getAccount(true).pipe(
              tap((account) => {
                // Unset USA numbers in the front end as it is being done on the back-end to gain speed
                if (account.account_status === UsallianceAccountStatuses.Closed) {
                  const newUser = user;
                  delete newUser.userDetails.agreementNumber;
                  delete newUser.userDetails.personNumber;
                  newUser.verification.bank = false;
                  const formattedUser = this.verificationService.getVerificationDetails(newUser);
                  this.userSubject.next(formattedUser);

                  this.notifService.notification(
                    'info',
                    `Your ${this.config.appName} Pay account has been closed due to inactivity. 
              You can re-open it anytime by clicking the 'Verify' button next to ${this.config.appName} Pay.`
                  );
                }
              })
            );
          }
          return of(null);
        })
      )
      .subscribe();
  }

  processCognitoCallback(url) {
    const parameter = url.split('&');
    const idToken = parameter[0].split('=')[1];
    const accessToken = parameter[1].split('=')[1];
    const exp = parameter[2].split('=')[1];
    this.setHostedUIData({ accessToken, idToken, exp });
    this.cognitoDataSubject.next({ accessToken, idToken, exp: Date.now() + exp * 100 });
  }

  setHostedUIData({ accessToken, idToken, exp }) {
    sessionStorage.setItem('hostedUI_access_token', accessToken);
    sessionStorage.setItem('hostedUI_id_token', idToken);
    sessionStorage.setItem('hostedUI_exp', String(Date.now() + exp * 100));
  }

  getHostedUIData(): { idToken: string; accessToken: string; exp: number } {
    const data = {
      accessToken: sessionStorage.getItem('hostedUI_access_token') || '',
      idToken: sessionStorage.getItem('hostedUI_id_token') || '',
      exp: Number(sessionStorage.getItem('hostedUI_exp')) || null,
    };
    if (Number(data.exp) - Date.now() > 0) {
      return data;
    }
    sessionStorage.setItem('hostedUI_access_token', '');
    sessionStorage.setItem('hostedUI_id_token', '');
    sessionStorage.setItem('hostedUI_exp', null);
    return {
      accessToken: '',
      idToken: '',
      exp: null,
    };
  }

  public getSellerDetailsById(id) {
    return this.http.get(`${this.config.apiUrl}/sellers/${id}`).pipe(
      pluck('data'),
      map((users) => users[0]),
      map((user) => this.verificationService.getVerificationDetails(user))
    );
  }

  // Set user's data
  configureUser(rawUser): Observable<IUser> {
    return of(rawUser).pipe(map((user) => this.verificationService.getVerificationDetails(user)));
  }

  public getFavs(hideLoaderInterceptor = false): Observable<ISellerListingWithUser[]> {
    let headers = new HttpHeaders();
    headers = hideLoaderInterceptor ? headers.set(HEADER_HIDE_LOADER, '1') : headers;

    return this.http.get(`${this.baseUrl}/fav`, { headers }) as Observable<ISellerListingWithUser[]>;
  }

  public processFav(listingId) {
    if (this.favIds?.indexOf(listingId) >= 0) {
      this.removeFav(listingId);
    } else {
      this.addFav(listingId);
    }
  }

  public addFav(listingId) {
    this.http
      .post(`${this.baseUrl}/fav`, { listingId })
      .pipe(pluck('data'))
      .subscribe((result: { favorites: IFavItem[] }) => {
        this.favIds = result.favorites.map((fav) => fav.listingId?._id);
        this.favs = result.favorites.map((fav) => fav.listingId).filter((fav) => !!fav);
        this.userFavSubject.next(this.favs);
      });
  }

  public removeFav(listingId) {
    return this.http
      .delete(`${this.baseUrl}/fav`, { params: { listingId } })
      .pipe(pluck('data'))
      .subscribe((result: { favorites: IFavItem[] }) => {
        this.favIds = result.favorites.map((fav) => fav.listingId?._id);
        this.favs = result.favorites.map((fav) => fav.listingId).filter((fav) => !!fav);

        this.userFavSubject.next(this.favs);
      });
  }

  public getUserNotifications(id: string): Observable<IReciverNotifications> {
    const subject = new Subject<IReciverNotifications>();
    this.getUser(id).subscribe(({ notifications, userDetails }) => {
      const { transactions } = notifications;
      subject.next({
        notifySms: transactions.sms,
        notifyEmail: transactions.email,
        notifyAndroid: transactions.iosPush,
        notifyIos: transactions.androidPush,
        phoneNumber: userDetails.phoneNumber,
      });
    });
    return subject.asObservable();
  }

  public sendLicenseVerificationLink(data: { phone: string; token: string }) {
    return this.http.post<any>(`${this.baseUrl}/vouched/send-verification`, data);
  }

  public checkUsersFunds() {
    return this.usallianceService.account.pipe(
      switchMap((account) =>
        this.loanService.all().pipe(
          map((loans) => {
            const approvedLoansFund = loans
              .filter((loan) => loan.status === UsallianceLoanStatuses.APPROVED)
              .reduce((total, loan) => total + +loan.amount.$numberDecimal, 0);
            return account.account_available_balance + (approvedLoansFund || 0);
          })
        )
      )
    );
  }

  public cancelLicenseVerification(data: { jobId: string }) {
    return this.http.post<any>(`${this.baseUrl}/vouched/cancel-verification`, data);
  }

  public submitDeleteAccountRequest() {
    return this.http.post(`${this.baseUrl}/account`, {});
  }

  public checkUserDelete() {
    return this.http.get(`${this.baseUrl}/account/delete/check`);
  }

  updateFlag(flags: Partial<IUser> | Record<string, any>) {
    return this.http
      .post(`${this.baseUrl}/update-flag`, flags, {
        headers: { 'X-No-Loader': '1' },
      })
      .pipe(
        tap(() => {
          // will not cover deeply nested string keys, you need to call getCurrentProfile again if needed
          const user = { ...this.userSubject.getValue(), ...flags };
          this.userSubject.next(user);
        })
      );
  }

  submitFeedback(message: string, isDealNow = false) {
    return this.http.post(`${this.config.apiUrl}/marketing/feedback`, { message, isDealNow });
  }

  getAvailedServiceVehicleStats() {
    return this.http.get<AvailedServiceVehicleStats>(`${this.config.apiUrl}/addons/statistics/vehicles`);
  }
}
