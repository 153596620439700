import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_CONFIG, IAppConfig } from 'src/app/config/config';
import { Buffer } from 'buffer';
import { NotificationService } from './notification.service';
import { heicTo } from 'heic-to';
import { LoadingService } from '.';
import generateShortId from 'ssid';
import qs from 'qs';
import { PosthogService } from './posthog.service';
import { PostHogFeatureFlags } from 'src/third-party-integrations/posthog';
import imageCompression from 'browser-image-compression';

const IMAGES_PATH = '/images/';
const NEXT_IMAGE_PATH = '/_next/image';
export interface Picture {
  images: string;
  sizes?: { [key: string]: string };
}

export interface UploadedFile {
  key: string;
  filename: string;
  originalname: string;
  mimetype: string;
  path: string;
  size: number;
  error: string;
}

export interface MultipleFileUploadResult {
  files: UploadedFile[];
}

interface SignedUrlResult {
  url: string;
}

export const MAX_UPLOAD_SIZE = 20000000;
export const DOCUMENT_FILE_SIZE = 50000000;
export const MAX_VIDEO_UPLOAD_SIZE = 5368709120;
export const SUPPORTED_IMAGE_EXTENSIONS = ['.heic', '.heif', '.jfif'];
export const VIDEO_EXTENSIONS = [
  '.mp4',
  '.mov',
  '.avi',
  '.wmv',
  '.flv',
  '.mkv',
  '.webm',
  '.ogg',
  '.3gp',
  '.3g2',
  '.m4v',
  '.mts',
  '.m2ts',
  '.ts',
  '.vob',
  '.rm',
  '.rmvb',
  '.asf',
];
export const SUPPORTED_VIDEO_EXTENSIONS = ['.mp4', '.webm'];

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  userId: string;
  private isNextImageEnabled: boolean;
  private resizeImageUpload: boolean;
  private closestMap: { [key: string]: number[] } = {};

  constructor(
    private readonly notificationService: NotificationService,
    private readonly http: HttpClient,
    private readonly loadingService: LoadingService,
    private readonly posthogService: PosthogService,
    @Inject(APP_CONFIG) private readonly config: IAppConfig,
  ) {
    this.isNextImageEnabled = this.posthogService.posthog.isFeatureEnabled(PostHogFeatureFlags.NextImageProcessor);
    this.resizeImageUpload = this.posthogService.posthog.isFeatureEnabled(PostHogFeatureFlags.ImageUploadResize);
  }

  public uploadImage(file: FormData, prefix?: string, fileType = 'image') {
    return new Observable((observer) => {
      if (file.has(fileType)) {
        const imageFileObject = file.get(fileType) as File;
        this.processSingleUpload(imageFileObject, prefix).subscribe(
          (fileData) => {
            observer.next({
              imageUrl: fileData.path,
            });
            observer.complete();
          },
          (error) => {
            observer.error(error);
          },
        );
      } else {
        observer.error('No image provided');
      }
      return {
        unsubscribe() {
          // interface
        },
      };
    });
  }

  checkFileType(buffer) {
    return this.http.post('/fileCheck/checkUploadedFile', buffer.toString('base64'));
  }

  public async validateImage(file: File, notify = true) {
    let newFile = file;
    if (!newFile) {
      return null;
    }

    if (file?.type.split('/')[0] !== 'image') {
      if (notify) this.notificationService.notification('error', `${file.name} is not an image.`);
      return null;
    }

    if (file.size > MAX_UPLOAD_SIZE) {
      if (notify)
        this.notificationService.notification('error', 'Maximum image size is 20mb. Please upload a smaller image');
      return null;
    }

    if (this.resizeImageUpload) {
      try {
        const result = await this.compressImage(file);
        return result;
      } catch {
        this.notificationService.notification(
          'error',
          'There was an error uploading this image, please try again or contact support if the issue persists. ',
        );
        return null;
      }
    }

    const slice = file.slice(0, 25);
    const arrayBuffer = await slice.arrayBuffer();
    const buffer = Buffer.from(arrayBuffer);
    const fileType = (await firstValueFrom(this.checkFileType(buffer))) as any;

    if (/image\/hei(c|f)/.test(file.type) || fileType.ext === 'heic' || fileType.ext === 'heif') {
      this.loadingService.setLoading(true);
      const blob = (await heicTo({ blob: file, type: 'image/jpeg', quality: 10 })) as any;
      const newName = file.name.replace(/\.[^/.]+$/, '.jpg');
      newFile = new File([blob], newName);
    }

    return newFile;
  }

  hasVideoExtension(file: File): boolean {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    return SUPPORTED_VIDEO_EXTENSIONS.includes(`.${fileExtension}`);
  }

  /**
   * Simple video validation, checks for the file type and extension
   * @param file
   * @returns
   */
  async validateVideo(file: File): Promise<{ success: boolean; error: string }> {
    const result = { success: false, error: '' };

    try {
      const slice = file.slice(0, 25);
      const arrayBuffer = await slice.arrayBuffer();
      const buffer = Buffer.from(arrayBuffer);
      const checkedFile = (await firstValueFrom(this.checkFileType(buffer))) as any;
      const hasValidExtension = SUPPORTED_VIDEO_EXTENSIONS.some((ext) => {
        const strToCheck = checkedFile?.ext || file.name.toLowerCase();
        return strToCheck.endsWith(ext);
      });

      if (!hasValidExtension) {
        const supportedFormats = SUPPORTED_VIDEO_EXTENSIONS.map((ext) => `${ext.slice(1).toUpperCase()}`).join(', ');
        result.error = `Invalid video format. Supported formats are ${supportedFormats}.`;
      } else {
        result.success = true;
      }
    } catch (e) {
      result.error = e.message;
    }

    if (result.error) {
      result.error = `${file.name} - ${result.error}`;
    }

    return result;
  }

  public uploadMultipleImages(formData: FormData, prefix?: string): Observable<UploadedFile[]> {
    return this.processMultipleFileUploads(formData, prefix);
  }

  public uploadMultipleFiles(formData: FormData, prefix?: string): Observable<MultipleFileUploadResult> {
    return this.processMultipleFileUploads(formData, prefix).pipe(map((files) => ({ files })));
  }

  private processMultipleFileUploads(formData: FormData, prefix?: string): Observable<UploadedFile[]> {
    return new Observable((observer) => {
      if (formData.has('uploads')) {
        const uploads = [];
        formData.getAll('uploads').forEach((file: File) => {
          uploads.push(this.processSingleUpload(file, prefix));
        });
        forkJoin(uploads).subscribe(
          (results: UploadedFile[]) => {
            observer.next(results);
            observer.complete();
          },
          (err) => {
            observer.error(err);
          },
        );
      } else {
        observer.error('No files passed to upload');
      }
      return {
        unsubscribe() {
          // interface
        },
      };
    });
  }

  private processSingleUpload(file: File, prefix = 'uploads/'): Observable<UploadedFile> {
    return new Observable((observer) => {
      const { name, type: mimetype, size } = file;
      const timestamp = +new Date();
      const extension = name.split('.').pop();
      const filePath = `${prefix + timestamp}-${generateShortId()}.${extension}`;
      const uploadUrl = this.createUploadUrl(filePath);

      const headers = new HttpHeaders({
        accept: 'application/json',
        'content-type': file.type,
      });
      this.http.post(uploadUrl, {}, { headers, observe: 'body', withCredentials: false }).subscribe(
        (res: SignedUrlResult) => {
          if (res && res.url) {
            this.http
              .put(res.url, file, {
                headers: headers.set('X-No-Authorization', '1'),
                withCredentials: false,
              })
              .subscribe(
                () => {
                  const key = filePath;
                  const publicPath = this.createPublicUrl(key);
                  observer.next({
                    key,
                    filename: name,
                    originalname: name,
                    mimetype,
                    path: publicPath,
                    size,
                    error: '',
                  });
                  observer.complete();
                },
                (uploadError) => {
                  observer.error(uploadError);
                },
              );
          } else {
            observer.error('An unknown error happened during upload');
            observer.complete();
          }
        },
        (signErr) => {
          observer.error(signErr);
        },
      );

      return {
        unsubscribe() {
          // interface
        },
      };
    });
  }

  createPublicUrl(encodedKey: string): string {
    return `${this.config.staticUrl}/${encodedKey}`;
  }

  public changeImageDimensionUrl(dimension: string, url: string) {
    return url.replace(this.config.staticUrl, `/images/${dimension}`);
  }

  private createUploadUrl(uploadFilePath: string): string {
    return `/upload/${uploadFilePath}`;
  }

  public makeImageSize(url: string, dimension: string) {
    if (url.match(/\.jfif+$/i)) return url;

    if (this.isNextImageEnabled) {
      return this.makeImageSizeV2(url, dimension);
    }
    // this adjusts for image urls in the ddatabase with a size already encoded - we ewant to discard and resize
    const sizePath = `${IMAGES_PATH}${dimension}`;
    const idx = url.indexOf(IMAGES_PATH);
    if (idx > -1) {
      const end = url.indexOf('/', idx + IMAGES_PATH.length + 1);
      return url.slice(0, idx) + sizePath + url.slice(end);
    }

    return url.replace(this.config.staticUrl, sizePath);
  }

  public makeImageSizeV2(url: string, dimension: string, quality = 75) {
    if (url?.endsWith('svg')) {
      return url;
    }
    const [width, height] = dimension.split('x');
    let host = this.config.websiteUrl;
    if (this.config.appDomain === 'localhost') {
      host = 'https://padev.xyz';
    }
    const closestWidth = this.closestMap[width] ?? this.getNextImageWidth(parseInt(width));
    const queryString = qs.stringify({
      url: [host, url].join(''),
      w: closestWidth,
      q: quality,
    });
    return `${NEXT_IMAGE_PATH}?${queryString}`;
  }

  getNextImageWidth(width: number) {
    const widths = [128, 256, 384, 640, 750, 828, 1080, 1200, 1920, 2048, 3840];
    return widths.find((e) => e >= width);
  }

  public addImageSize(pic: Picture, dimension: string, label?: string) {
    if (!pic.sizes) {
      pic.sizes = {};
    }

    const key = label ?? `sz${dimension}`;
    if (!pic.sizes[key]) pic.sizes[key] = this.makeImageSize(pic.images, dimension);
    return pic;
  }

  async convertHeicToJpeg(file: File): Promise<File> {
    try {
      const convertedBlob = await heicTo({ blob: file, type: 'image/jpeg' });
      return new File([convertedBlob as BlobPart], file.name.replace(/\.[^/.]+$/, '.jpg'), { type: 'image/jpeg' });
    } catch (error) {
      console.error('Error converting HEIC to JPEG:', error);
      throw error;
    }
  }

  async compressImage(file: File): Promise<File> {
    const supportedFileTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/bmp', 'image/heic'];

    const fileSizeInMB = file.size / 1024 / 1024;
    const fileType = file.type;

    // Check if the file type is supported
    if (!supportedFileTypes.includes(fileType)) {
      return file;
    }

    // Set thresholds for high-res images
    const jpegThreshold = 10; // MB
    const pngThreshold = 15; // MB
    const heicThreshold = 10; // MB (you can adjust this as needed)

    let shouldCompressDirectly = false;

    // Check file type and size
    if (fileType === 'image/jpeg' && fileSizeInMB > jpegThreshold) {
      shouldCompressDirectly = true;
    } else if (fileType === 'image/png' && fileSizeInMB > pngThreshold) {
      shouldCompressDirectly = true;
    } else if (fileType === 'image/heic' && fileSizeInMB > heicThreshold) {
      shouldCompressDirectly = true;
    } else if (fileType === 'image/webp' && fileSizeInMB > jpegThreshold) {
      // Assuming WebP has similar threshold to JPEG
      shouldCompressDirectly = true;
    } else if (fileType === 'image/bmp' && fileSizeInMB > pngThreshold) {
      // Assuming BMP has similar threshold to PNG
      shouldCompressDirectly = true;
    }

    if (shouldCompressDirectly && fileType !== 'image/heic') {
      // Compress directly without loading into an Image object
      const options = {
        maxWidthOrHeight: 3840,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);
        return compressedFile;
      } catch (error) {
        console.error('Error during image compression:', error);
        throw error;
      }
    } else if (fileType === 'image/heic') {
      try {
        return this.convertHeicToJpeg(file);
      } catch (error) {
        console.error('Error converting HEIC to JPEG:', error);
        throw error;
      }
    } else {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = async () => {
          const width = img.width;
          const height = img.height;

          // Check if the resolution is more than 3840 x 2160
          if (width > 3840 || height > 2160) {
            const options = {
              maxWidthOrHeight: 3840,
              useWebWorker: true,
            };

            try {
              const compressedFile = await imageCompression(file, options);
              resolve(compressedFile);
            } catch (error) {
              console.error('Error during image compression:', error);
              reject(error);
            }
          } else {
            resolve(file);
          }
        };

        img.onerror = (error) => {
          console.error('Error loading image:', error);
          reject(error);
        };
      });
    }
  }
}
