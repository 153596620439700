import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LogoutGuard } from './core/guards/logout.guard';
import { FeatureFlagGuard } from './core/guards/feature-flag.guard';
import { PostHogFeatureFlags } from 'src/third-party-integrations/posthog';
import { AuthorizationGuard } from './core/guards/authorization.guard';
import { AuthorizedAction, AuthorizedSubject } from './core/services/authorizaton.service';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'search-listings',
        loadChildren: () => import('./listing-search/listing-search.module').then((m) => m.ListingSearchModule),
        canActivate: [AuthGuard, FeatureFlagGuard],
        data: { requiredFeature: PostHogFeatureFlags.BrowseListings },
      },
      {
        path: 'vehicle_details/:slug',
        redirectTo: 'listing/:slug',
      },

      // TODO for deletion - old route for create listing
      {
        path: 'create-listing/finish-listing/:id',
        redirectTo: 'listing/steps/:id',
      },
      {
        path: 'listing',
        loadChildren: () => import('./listing/listing.module').then((m) => m.ListingModule),
        canActivate: [AuthGuard, FeatureFlagGuard],
        data: { requiredFeature: PostHogFeatureFlags.ManageListings },
      },
      {
        path: 'favorites',
        loadChildren: () => import('./favorites/favorites.module').then((m) => m.FavoritesModule),
        canActivate: [AuthGuard, FeatureFlagGuard],
        data: { requiredFeature: PostHogFeatureFlags.ManageListings },
      },
      {
        path: 'account',
        loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'business-account',
        loadChildren: () => import('./business-account/business-account-module').then((m) => m.BusinessAccountModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'inbox/:chatId',
        loadChildren: () => import('./marketing-inbox/marketing-inbox.module').then((m) => m.MarketingInboxModule),
        canActivate: [AuthGuard, AuthorizationGuard],
        data: {
          requiredPermission: {
            action: AuthorizedAction.manage,
            resource: AuthorizedSubject.chat,
          },
        },
      },
      {
        path: 'inbox',
        loadChildren: () => import('./marketing-inbox/marketing-inbox.module').then((m) => m.MarketingInboxModule),
        canActivate: [AuthGuard, AuthorizationGuard],
        data: {
          requiredPermission: {
            action: AuthorizedAction.manage,
            resource: AuthorizedSubject.chat,
          },
        },
      },
      {
        path: 'deals',
        loadChildren: () => import('./deals/deals.module').then((m) => m.DealsModule),
        canActivate: [AuthGuard, AuthorizationGuard],
        data: {
          requiredPermission: {
            action: AuthorizedAction.manage,
            resource: AuthorizedSubject.deal,
          },
        },
      },
      {
        path: 'test-drives',
        loadChildren: () => import('./test-drives/test-drives.module').then((m) => m.TestDrivesModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'services',
        loadChildren: () =>
          import('./additional-services/additional-services.module').then((m) => m.AdditionalServicesModule),
        canActivate: [AuthGuard, AuthorizationGuard],
        data: {
          requiredPermission: {
            action: AuthorizedAction.manage,
            resource: AuthorizedSubject.services,
          },
        },
      },
      {
        path: 'leads',
        canActivate: [AuthGuard, FeatureFlagGuard, AuthorizationGuard],
        data: {
          requiredFeature: PostHogFeatureFlags.DealerAcquisitionModule,
          requiredPermission: {
            action: AuthorizedAction.manage,
            resource: AuthorizedSubject.lead,
          },
        },
        loadChildren: () =>
          import('./dealer-acquisition/dealer-acquisition.module').then((m) => m.DealerAcquisitionModule),
      },
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
        canActivate: [LogoutGuard],
      },

      { path: 'privacy', redirectTo: '/privacy-policy' },
      {
        path: 'privacy-policy',
        loadChildren: () =>
          import('./marketing-components/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
      },

      {
        path: 'terms',
        loadChildren: () => import('./marketing-components/tos/tos.module').then((m) => m.TosModule),
      },
      {
        path: 'garage',
        loadChildren: () => import('./garage/garage.module').then((m) => m.GarageModule),
        canActivate: [AuthGuard, FeatureFlagGuard, AuthorizationGuard],
        data: {
          requiredFeature: PostHogFeatureFlags.ManageListings,
          requiredPermission: {
            action: AuthorizedAction.manage,
            resource: AuthorizedSubject.listing,
          },
        },
      },
      {
        path: 'verification-completed',
        loadChildren: () =>
          import('./prove-verification/verification-completed/verification-completed.module').then(
            (m) => m.VerificationCompletedModule,
          ),
      },
      {
        path: 'help-center',
        loadChildren: () => import('./help-center/help-center.module').then((m) => m.HelpCenterModule),
      },
      {
        path: 'account-deleted',
        loadChildren: () => import('./auth/account-deleted/account-deleted.module').then((m) => m.AccountDeletedModule),
        canActivate: [LogoutGuard],
      },

      { path: '', pathMatch: 'full', redirectTo: 'search-listings' },
    ],
  },
  { path: '**', redirectTo: '/search-listings' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
