<app-modal #modal [modalConfig]="modalConfig">
  <div class="d-flex flex-column align-items-center justify-content-center">
    <img src="assets/lady_holding_check.svg" alt="success" class="success-icon mb-3" />
    <div class="header-text-cash text-center py-3">
      All set! Your vehicle has been submitted. Any offers from dealers will appear in your chat page.
    </div>

    <button class="btn-gray w-100" (click)="onViewGarageClick()">Got it!</button>
  </div>
</app-modal>
