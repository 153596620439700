export enum PostHogFeatureFlags {
  ListingAuctionsEnabled = 'ListingAuctionsEnabled',
  ChatDocsShare = 'chat-docs-share',
  ServiceCouponCodes = 'service-coupon-codes',
  ProveVerification = 'prove-verification',
  BottomNavShow = 'bottom-nav-show',
  ProveMobileAuth = 'prove-mobile-auth',
  NextImageProcessor = 'next-image-processor',
  ImageUploadResize = 'image-upload-resize',
  ChatCreationLimit = 'chat-creation-limit',
  MoneyPage2024 = 'money-page-2024',
  YassiTitleCheck = 'yassi-title-check',
  HoldFuturePayment = 'hold-future-payments',
  ProveKyb = 'prove-kyb',
  UploadCustomVideos = 'upload-custom-videos',
  ManageListings = 'manage-listings',
  BrowseListings = 'browse-listings',
  VDPViewHistoryReport = 'vdp-history-report-view',
  loanPayoffService = 'loan-payoff-service',
  Platoforms = 'platoforms',
  DealerAcquisitionModule = 'dealer-acquisition-module',
  DealerTestDrive = 'dealer-test-drive',
  LeadsMarketInsights = 'leads-market-insights',
}
